.embeddedServiceHelpButton {
    .helpButton {
        .uiButton {
            background-color: #000;
            font-family: "Arial", sans-serif;
        }
        &:focus {
            outline: 1px solid #000;
        }
    }
}