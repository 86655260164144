#quickViewModal,
#editProductModal,
#editWishlistProductModal{

    .modal-body{
        .product-quickview{
            &.product-detail{
                position: relative;
                padding-left: 0;
                padding-right: 0;
                margin-top: 0;

                @include media-breakpoint-up(sm) {
                    & > .row{
                        & > .product-media-wrapper{
                            padding-right: 10px;
                        }

                        & > .product-info-wrapper{
                            padding-left: 10px;
                        }
                    }
                }

                .product-image-carousel{
                    .carousel{
                        flex-direction: column;

                        .carousel-indicators{
                            display: none;
                        }

                        .carousel-inner{
                            order: 1;
                            width: 100%;
                            padding-left: 0;

                            .image-zoom-opener{
                                display: none;
                            }
                        }

                        button.carousel-control-prev,
                        button.carousel-control-next{
                            display: inline-block;
                        }
                    }
                }

                .prices{
                    .price{
                        .percentage{
                            position: absolute;
                            top: 10px;
                            left: 0;
                            z-index: 1;
                            @include percentageLabel();
                        }
                    }
                }

                #lover-card-content-asset{
                    display: none;
                }

                .lover-card-opener{
                    cursor: default;

                    span{
                        border-bottom: 0 none !important;
                    }
                }
            }
        }
    }

    .modal-footer{
        .cart-and-ipay{
            @include media-breakpoint-up(sm) {
                width: 100%;
                margin-left: auto;
            }

            .btn-primary{
                width: 100%;
            }
        }
    }
}
