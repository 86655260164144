.black{
    color: $black;
}

.black-backgound{
    background-color: $black;
}

.white{
    color: $white;
}

.white-backgound{
    background-color: $white;
}

.dark-gray{
    color: $dark-gray;
}

.dark-gray-backgound{
    color: $dark-gray;
}

.darker-gray{
    color: $darker-gray;
}

.darker-gray-backgound{
    color: $darker-gray;
}

.medium-gray{
    color: $medium-gray;
}

.medium-gray-backgound{
    color: $medium-gray;
}

.light-gray{
    color: $light-gray;
}

.light-gray-backgound{
    background-color: $light-gray;
}

.very-light-gray{
    color: $very-light-gray;
}

.very-light-gray-backgound{
    background-color: $very-light-gray;
}
