/*
    wg10: just text
*/

.wg10{
    $page-padding-x_mobile: 12px;

    .wg-text-box {
        padding: 20px $page-padding-x_mobile;
        margin-top: 0;

        @include media-breakpoint-up(sm) {
            padding: 20px $page-padding-x;
        }

        h2, .h2,
        h3, .h3,
        h4, .h4{
            span{
                font-weight: 400;
            }
        }
    }

    &.medium-content,
    &.small-content{
        .wg-text-box{
            width: 100% !important;
        }
    }

    &.border-top-bottom{
        .wg-text-box {
            border-top: 1px solid $light-gray;
            border-bottom: 1px solid $light-gray;
            padding-top: 32px;
            padding-bottom: 40px;
        }
    }

    &.border-top{
        .wg-text-box {
            padding-top: 32px;
            border-top: 1px solid $light-gray;
        }
    }

    &.border-bottom{
        .wg-text-box {
            border-bottom: 1px solid $light-gray;
            padding-bottom: 40px;
        }
    }

    &.section-links{
        .wg-text-box{
            @include flexbox-center-center();
            flex-wrap: wrap;

            a{
                font-size: $font-size-12px;
                font-weight: 700;
                letter-spacing: 0.4pt;
                text-transform: uppercase;
                margin-top: 10px;
                margin-bottom: 10px;

                & + a{
                    margin-left: 30px;

                    @include media-breakpoint-up(sm) {
                        margin-left: 40px;
                    }
                }
            }
        }
    }
}
