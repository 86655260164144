.wishlistItemCards{
    .card{ // .product-info
        .item-details{
            @include flexbox-left-top();
            flex-wrap: wrap;

            & > *{
                width: 100%;
            }

            .item-attributes{
                font-size: $font-size-14px;
            }

            .line-item-availability{
                display: block;
                margin-top: 10px;
                color: #959595;
            }

            .line-item-quantity{
                margin-bottom: 0;
            }

            .select-attributes-wishList{
                button{
                    font-size: $font-size-12px;
                }
            }
        }

        .product-line-toolbar{
            flex-wrap: wrap;
            justify-content: flex-start;
            width: auto;

            &  > * {
                margin-top: 15px;
            }

            .wishlist-hide{
                padding-left: 20px;
                padding-right: 20px;
                margin-bottom: 0;

                label{
                    font-size: $font-size-14px;
                }
            }
        }
    }
}

.wishlist-page,
.account-page{
    .wishlistItemCards,
    .card-header{
        a.button{
            text-transform: uppercase;
            font-size: $font-size-12px;
            font-weight: 700;
            letter-spacing: 0.4pt;
        }
    }

    .wishlistItemCards{
        .select-attributes-wishList{
            button{
                @include media-breakpoint-down(md){
                    font-size: 0.688rem !important;
                }
            }
        }
    }

    .social-container{
        padding-top: 15px;
        border-top: 1px solid $medium-gray;
    }

    .my-account{
        text-align: center;
    }
}

.account-page{
    .wishlistItemCards{
        .card{
            display: none;

            &:nth-child(1),
            &:nth-child(2){
                display: block;
            }
        }

        .item-details{
            font-size: $font-size-14px;
        }

        .line-item-quantity{
            width: auto;
        }

        .product-line-toolbar{
            width: auto;
            margin-left: auto;

            & > *{
                margin-top: 0;
            }
        }

        .edit-add-to-wishlist,
        .wishlist-hide,
        .add-to-cart-wishList,
        .social-container,
        .select-attributes-wishList{
            display: none;
        }
    }

    .my-account.wishlist{
        display: none;
    }
}

.wishlist-page{
    .social-container{
        padding-top: 20px;
    }

    .wishlistItemCards{
        .card{
            padding-top: 30px;
            padding-bottom: 30px;

            &:first-child{
                padding-top: 0;
            }
        }

        .item-image{
            img{
                max-width: 110px;

                @include media-breakpoint-up(sm){
                    max-width: 145px;
                }
            }
        }

        .line-item-name{
            @include media-breakpoint-up(md){
                font-size: 1rem;
                width: 65%;
            }
        }

        .line-item-unit-price{
            @include media-breakpoint-up(md){
                width: 35%;
                text-align: right;
                margin-bottom: 0;

                .price{
                    & > span:last-child{
                        justify-content: flex-end;
                    }

                    .sales{
                        margin-right: 0;
                    }

                    .promo-lover-card-opener-container{
                        width: 100%;
                    }
                }
            }
        }

        .social-container,
        .add-to-cart-wishList{
            width: auto;
            margin-left: auto;
            margin-top: 15px;
        }

        .social-container{
            padding-top: 0;
            border-top: 0 none;
        }

        .add-to-cart-wishList{
            padding-left: 15px;
        }

        @include media-breakpoint-down(md){
            .line-item-name{
                font-size: $font-size-14px;
                order: 1;
            }

            .line-item-unit-price{
                order: 2;
            }

            .select-attributes-wishList{
                order: 3;
            }

            .item-attributes{
                order: 3;
            }

            .line-item-quantity{
                order: 4;
            }

            .product-line-toolbar{
                order: 5;
            }

            .social-container,
            .add-to-cart-wishList{
                width: 100%;
                margin-left: 0;
            }

            .add-to-cart-wishList{
                padding-left: 0;
                button{
                    width: 100%;
                }
            }

            .item-details{
                .social-container,
                .add-to-cart-wishList{
                    display: none !important;
                }
            }
        }

        .product-line-toolbar{
            .wishlist-item_public-state{
                &.public-state_hidden{
                    svg.public-hidden{
                        display: inline;
                    }
                    svg.public-visible{
                        display: none;
                    }
                }

                &.public-state_visible{
                    svg.public-visible{
                        display: inline;
                    }
                    svg.public-hidden{
                        display: none;
                    }
                }
            }
        }
    }

    .my-account.wishlist{
        margin: 25px 0 10px;

        button{
            min-width: 150px;
        }
    }
}
