.wg-accordion{
    $page-padding-x_mobile: 12px;
    
    .wg-text-box{
        padding: 0 $page-padding-x_mobile;
        margin-top: 0;

        @include media-breakpoint-up(sm) {
            padding: 0 $page-padding-x;
        }

        .accordion{
            .accordion-body{
                padding-top: 10px;
                padding-bottom: 30px;

                h3, .h3,
                h4, .h4{
                    font-size: $font-size-14px;

                    & + p{
                        margin-top: 10px;
                    }
                }

                p{
                    strong{
                        font-weight: 500;
                    }

                    & + ul{
                        margin-top: 10px;
                    }

                    & + h3,
                    & + .h3,
                    & + h4,
                    & + .h4{
                        margin-top: 30px;
                    }
                }
            }

            .open-workwithus-form {
                margin-bottom: 30px;
            }
        }
    }
}
