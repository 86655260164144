$watermark-size-xy: 50px;
$swatch-size-xy: 38px;
$swatch-size-xy_medium: 33px;
$swatch-size-xy_small: 28px;
$btn-toggle-colors-width: 25px;

.product-tile{
    position: relative;

    .image-container{
        position: relative;

        .watermarks {
            width: $swatch-size-xy;
            position: absolute;
            top: 10px;
            left: 20px;
            display: flex;
            flex-direction: column;

            img {
                &.second-watermark {
                    width: $watermark-size-xy;
                    @include media-breakpoint-down(lg){
                        width: $swatch-size-xy;
                    }
                }
                &:not(:first-child) {
                    margin-top: 12px;
                }
            }
        }

        .percentage {
            position: absolute;
            top: 0;
            @include percentageLabel();

            + .watermarks {
                top: 40px;
            }
        }

        .tile-image{
            display: inline-block;
            width: 100%;
            max-width: 100%;
        }

        .quickview,
        .add-to-wishlist{
            @include flexbox-center-center();
            position: absolute;
            right: 10px;
            z-index: 1;
            width: 38px;
            height: 38px;
            border-radius: 38px;
            background-color: $medium-gray;

            svg {
                width: 20px;
                height: 20px;
            }

            .heart-full {
                display: none;
            }

            &.is-in-wishlist {
                .heart {
                    display: none;
                }

                .heart-full {
                    display: block;
                }
            }
        }

        .quickview{
            top: 10px;
        }

        .add-to-wishlist{
            top: 53px;
        }
    }

    .tile-body{
        font-size: $font-size-14px;
        margin-top: 15px;

        .color-swatches{
            @include flexbox-left-center();
            &.expanded{
                position: relative;

                .swatches{
                    position: absolute;
                    left: 0;
                    background-color: $white;
                    z-index: 1;
                    overflow: visible;
                    height: auto;
                    top: 0;
                }

                .toggle-color-swatches{
                    &:before{
                        content: '-';
                    }
                }
            }

            .swatches{
                @include flexbox-left-center();
                flex-wrap: wrap;
                height: $swatch-size-xy_small;
                width: calc(100% - #{$btn-toggle-colors-width});
                overflow: hidden;
                margin-bottom: 15px;

                a{
                    //padding-bottom: 1px;
                    //border-bottom: 1px solid transparent;
                    padding-right: 10px;
                    background-color: $white;
                    position: relative;

                    &:after{
                        content: '';
                        width: calc(100% - 12px);
                        height: 1px;
                        display: inline-block;
                        position: absolute;
                        left: 1px;
                        bottom: 7px;
                        z-index: 1;
                        background-color: transparent;
                    }

                    &:hover{
                        text-decoration: none;
                        //border-bottom-color: $black;
                        &:after{
                            background-color: $black;
                        }
                    }

                    span{
                        display: inline-block;
                        width: $swatch-size-xy_small;
                        height: $swatch-size-xy_small;
                        text-indent: -9999px;
                        border: 1px solid $light-gray;
                    }
                }

                .variants-number {
                    font-size: 16px;
                }
            }

            .toggle-color-swatches{
                width: $btn-toggle-colors-width;
                height: $swatch-size-xy_small;
                margin-left: auto;
                margin-bottom: 15px;
                text-align: center;
                font-weight: 300;

                @include media-breakpoint-up(md) {
                    height: $swatch-size-xy_medium;
                }

                @include media-breakpoint-up(lg) {
                    height: $swatch-size-xy;
                }

                &:before{
                    content: '+';
                    font-size: 24px;
                    color: $black;
                    line-height: $swatch-size-xy_small;
                    display: inline-block;
                    width: 100%;

                    @include media-breakpoint-up(md) {
                        line-height: $swatch-size-xy_medium;
                    }

                    @include media-breakpoint-up(lg) {
                        line-height: $swatch-size-xy;
                    }
                }

                span{
                    @include accessibleLabel();
                }
            }
        }

        .pdp-link{
            //margin-top: 15px;
            height: 22px;
            overflow: hidden;

            .link{
                font-size: inherit;
                font-weight: 300;
            }
        }

        .price{
            margin-top: 5px;
            //margin-bottom: 10px;

            & > span {
                margin: 3px 10px 3px 0;
            }
        }
    }
}

.product-grid {
    &.row-cols-4, &.row-cols-2 {
        .product-tile {
            .image-container {
                .watermarks {
                    img {
                        @include media-breakpoint-down(lg) {
                            width: $swatch-size-xy_small;
                        }
                    }
                }
            }
        }
    }
}