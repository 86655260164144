.product-detail {
    $page-padding-x_mobile: 12px;

    margin: 15px 0 0;
    padding-left: calc($page-padding-x_mobile + 6px);
    padding-right: calc($page-padding-x_mobile + 6px);

    @include media-breakpoint-up(md) {
        padding-left: calc($page-padding-x + 6px);
        padding-right: calc($page-padding-x + 6px);
    }

    .product-breadcrumb{
        .breadcrumb{
            margin-top: 0;
            margin-bottom: 5px;

            li{
                padding-top: 0;
            }
        }
    }

    .product-name{
        text-transform: uppercase;
        line-height: 1.2;
        margin: 10px 0;
        font-size: $font-size-25px;
        font-weight: 600;
        letter-spacing: 0.4pt;

        .product-sku{
            display: block;
            font-size: $font-size-14px;
            letter-spacing: normal;
            font-weight: 400;
            text-transform: none;
            margin-top: 7px;
        }
    }

    .prices{
        font-size: $font-size-base;

        @include media-breakpoint-up(md) {
            font-size: $font-size-18px;
        }

        margin: 15px 0;
        letter-spacing: 0.4pt;

        .lover-card-opener {
            font-size: $font-size-base;

            @include media-breakpoint-up(md) {
                font-size: $font-size-18px;
            }

            span{
                border-bottom: 1px solid $black;
                padding-bottom: 1px;
            }

            &:hover{
                span{
                    border-bottom: 0 none;
                    padding-bottom: 0;
                }
            }
        }

        .price {
            font-size: inherit;
            @include flexbox-center-center();
        }
    }

    .attributes{
        .attribute{
            display: flex;
            flex-direction: column;

            label{
                font-size: $font-size-14px;
                margin-bottom: 10px;
            }

            select{
                padding-top: 10px;
                padding-bottom: 10px;
            }

            input[type="number"]{
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        div[data-attr="color"]{
            margin-top: 15px;
            margin-bottom: 15px;

            .attribute{
                span.color.non-input-label{
                    display: none
                }

                .attribute-wrapper{
                    @include flexbox-left-center();
                    flex-wrap: wrap;

                    .color-attribute{
                        margin-right: 20px;
                        margin-bottom: 10px;

                        span.color-value{
                            display: inline-block;
                            width: 50px;
                            height: 50px;
                            border: 1px  solid $light-gray;
                            position: relative;

                            &:after{
                                content: "";
                                display: block;
                                position: absolute;
                                bottom: -6px;
                                height: 2px;
                                width: 100%;
                                background-color: transparent;
                                margin-top: 2px;
                            }

                            &.selected{
                                &:after{
                                    background-color: $black;
                                }
                            }
                        }
                    }
                }

                &.quantity{
                    label{
                        display: none;
                    }
                }
            }
        }

        div.row.size-labels{

            @include media-breakpoint-down(md) {
                flex-flow: column-reverse;
            }

            & > div{
                margin-bottom: 15px;

                @include media-breakpoint-up(md) {
                    margin: 15px 0 0;
                }

                label,
                .size-chart-opener{
                    font-size: $font-size-14px;
                    margin-bottom: 15px;
                }

                label{
                    text-transform: unset;
                    color: $black;
                }

                &.size-chart{
                    .size-chart-opener{
                        font-weight: 700;
                        text-transform: uppercase;
                        letter-spacing: 0.4pt;
                    }
                }
            }
        }

        div[data-attr="size"], div[data-attr="size2"] {
            & > div{
                margin: 0 0 15px;
            }

            @include media-breakpoint-up(md){
                div[class^="col"]:not(.quantity){
                    padding-right: 20px;
                }
            }

            label{
                display: none;
            }

            select{
                font-weight: 300;
            }

            .size-buttons {
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 0;
                display: flex;
                flex-direction: row;
                white-space: nowrap;
                flex-wrap: wrap;
                text-align: center;

                > li {
                    min-width: 44px;
                    height: 42px;
                    border: 1px solid $black;
                    margin-right: 8px;
                    margin-bottom: 8px;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &.not-available {
                        opacity: 0.2;
                    }

                    &:disabled {
                        cursor: not-allowed;
                    }

                    &.unselectable-container {
                        display: none;
                    }


                    &.selected {
                        > button {
                            border: none;
                            color: $white;
                            background-color: $black;
                        }
                    }

                    > button {
                        display: flex; // for Safari
                        justify-content: center; // for Safari
                        color: $black; // for Safari
                        padding-block: 10px;
                        height: 100%;
                        width: 100%;
                        border: none;
                        background-color: $white;

                        &:disabled {
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }

    .promotions{
        margin: 10px 0;

        .promotions-wrapper{
            background-color: $gray-e6;
            padding: 10px 20px;
            margin-bottom: 0;

            & + .promotions-wrapper{
                margin-top: 5px;
            }

            img {
                max-width: 100%;
            }

            .campagn-name{
                font-weight: 700;

                button{
                    font-size: 1rem;
                    margin-bottom: 10px;
                }
            }

            .promo{
                margin: 4px 0;
                font-weight: 400;

                i{
                    display: block;
                    font-size: $font-size-12px;
                }
            }

            .promo-lover-card-image{
                width: 100%;
                max-width: 99px;
                height: 100%;
                margin-left: auto;
                background: transparent url('../images/promo-lover-card.png') no-repeat center center;
                background-size: contain;
            }
        }
    }

    .klarna-banner{
        > div[class^="col"] {
            @media (max-width: 768px){
                padding: 0;
            }

        }
        p{
            font-weight: 400;
            padding: 14px 115px 14px 18px;
            margin: 5px 0 15px;
            background: #FFB3C7 url('../images/svg/klarna-logo-black.svg') no-repeat 95% center;
            background-size: 90px 20px;
        }
    }

    .product-availability{
        font-size: $font-size-14px;
        margin: 5px 0;

        ul{
            @include resetUl();
        }
    }

    .cart-and-ipay{
        justify-content: space-between;
        margin: 15px 0;

        .add-to-cart,
        .notifyMeButtonContainer{
            display: inline-block;
            margin-right: 20px;
            width: calc(100% - 77px);

            .notifyme-availability {
                width: 100%;
            }
        }

        .add-to-wishlist{
            display: inline-block;
            padding: 12px;
            border: 1px solid $black;

            svg{
                width: 25px;
                height: 25px;
            }

            .heart-full {
                display: none;
            }

            &.is-in-wishlist {
                .heart {
                    display: none;
                }

                .heart-full {
                    display: inherit;
                }
            }
        }
    }

    .description-and-detail{
        margin: 40px 0 15px;

        .accordion{
            .accordion-item{
                .accordion-button{
                    &::after{
                        margin-left: 10px;
                    }
                }

                .accordion-body{
                    font-size: $font-size-14px;
                    font-weight: 300;

                    p{
                        padding: 0;
                        margin: 0;
                    }

                    p + p{
                        margin-top: 10px;
                    }

                    ul {
                        margin: 0;
                    }
                }
            }
        }
    }

    .availability-in-store{
        text-transform: uppercase;
        margin: 5px 0 25px;

        svg{
            width: 25px;
            height: 25px;
            margin-left: 0;
            margin-right: 7px;
        }
    }

    .product-image-carousel{
        .carousel{
            @include flexbox-left-top();

           .carousel-indicators{ // thumbs
                display: none;
                position: unset;
                margin: 0;

                [data-bs-target]{
                    width: 100%;
                    height: auto;
                    opacity: 1;
                    transition: none;
                    margin: 0;
                    text-indent: unset;

                    & + [data-bs-target]{
                        margin-top: 10px;
                    }

                    img{
                        display: inline-block;
                        width: 100%;
                    }
                }
            }

            .carousel-inner-content {
                position: relative;

                .watermarks {
                    position: absolute;
                    width: 38px;
                    top: 10px;
                    left: 20px;
                    @include media-breakpoint-up(lg){
                        left: 40px;
                    }
                    z-index: 1;
                    display: flex;
                    flex-direction: column;

                    img {
                        &.second-watermark {
                            width: 50px;
                            @include media-breakpoint-down(lg){
                                width: 38px;
                            }
                        }
                        &:not(:first-child) {
                            margin-top: 12px;
                        }
                    }
                }

                .percentage {
                    position: absolute;
                    z-index: 9;
                    top: 0;
                    @include percentageLabel();

                    + .watermarks {
                        top: 40px;
                    }
                }
            }

            .carousel-inner{

                .carousel-item{
                    img{
                        display: inline-block;
                        width: 100%;
                    }
                }

                .image-zoom-opener{
                    display: none;
                    position: absolute;
                    top: 80px;
                    left: 15px;
                    z-index: 1;
                    width: 49px;
                    height: 49px;
                    background: transparent url('../images/svg/zoom.svg') no-repeat center center;
                    background-size: 49px 49px;
                    border: 0 none;

                    @media (min-width: 769px){
                        display: inline-block;
                    }
                }
            }

            @include media-breakpoint-up(lg){
                .carousel-indicators{
                    @include flexbox-left-top($flex-direction: column);
                    width: 13%;
                    padding-right: 17px;
                }

                .carousel-inner-content {
                    padding-left: 17px;
                    width: 87%;
                }

                .carousel-inner{
                    width: 87%;
                }

                button.carousel-control-prev,
                button.carousel-control-next{
                    display: none!important;
                }
            }

            @media (min-width: 769px) and (max-width: 1023.98px){
            //@include media-breakpoint-only(md){
                flex-direction: column;

                .carousel-indicators{
                    @include flexbox-left-top();
                    order: 2;
                    flex-wrap: wrap;
                    width: 100%;
                    margin: 10px 0;
                    //padding-right: 17px;

                    [data-bs-target]{
                        max-width: 82px;
                        margin: 5px 0 5px;

                        &:first-child,
                        & + [data-bs-target]{
                            margin-right: 10px;
                            margin-top: 5px;
                        }
                    }
                }

                .carousel-inner{
                    order: 1;
                    width: 100%;
                    //padding-left: 17px;
                }

                button.carousel-control-prev,
                button.carousel-control-next{
                    display: none;
                }
            }

            .carousel-control-prev,
            .carousel-control-next{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                width: 35px;
                height: 35px;
                background-size: 26px 11px;
                opacity: 1;
                transition: none;
            }

            .carousel-control-prev{
                left: 0;
                background: $white url('../images/svg/arrow-left.svg') no-repeat center center;
            }

            .carousel-control-next{
                right: 0;
                background: $white url('../images/svg/arrow-right.svg') no-repeat center center;
            }
        }
    }

    .product-media-container,
    .product-info-container{
        margin-bottom: 30px;

        @media (max-width: 768px){
            flex: 0 0 auto;
            width: 100%;
        }
    }

    .product-media-container{
        padding-left: 0;
        padding-right: 0;

        @media (min-width: 769px){
            padding-right: 17px;
        }

        .price{
            del,
            .sales{
                display: none;
            }

            .percentage{
                @include media-breakpoint-up(lg){
                    left: 17px;
                }
            }
        }
    }

    .product-info-container{

        @media (min-width: 769px){
            padding-right: 0;
            padding-left: 17px;
        }

        .prices{
            .price{
                .percentage{
                    display: none;
                }
            }
        }
    }

    .selected-store-with-inventory {
        font-size: $font-size-14px;

        .store-name {
            font-size: $font-size-14px;
        }

        address {
            margin: 0;
        }

        .pickupfrom {
            padding-bottom: 10px;
        }
    }
}

.gift-card-request-form {
    .row {

        .image-container {
            min-height: auto;

            img {
                width: auto;
                margin: 0 auto;
            }
        }
    }

    .product-option{
        margin-top: 6%;

        .legend{
            font-size: $font-size-18px;
            font-weight: 700;
            margin-bottom: 15px;
        }
    }

    .wg-text-box {
        @include media-breakpoint-up(md){
            display: flex;
            flex-direction: column;
            height: 100%;

            p + form{
                padding-top: 25px;
            }

            form{
                margin-top: auto;
            }
        }
    }
}


.product-bottom{
    .products-carousel + .products-carousel{
        margin-top: 10px;
    }

    .product-page-widget{
        margin: 15px 0;
    }
}

#inStoreInventoryModal {

    .align-items-end {
        align-items: flex-end;
    }

    &.modal.fade {

        .modal-body {
            padding: 0;
        }

        .modal-dialog.in-store-inventory-dialog {

            max-width: 500px;

            .close  {
                font-size: 0;
            }

            .store-locator{

                .container {
                    margin: 0;
                }

                .detect-location {
                    display: none;
                }
            }

            .results-card {

                .store-locator-no-results {
                    font-size: $font-size-14px;
                }

                .card-header {
                    text-transform: lowercase;
                    font-size: $font-size-14px;
                    font-weight: 300;
                    padding: 12px 0 24px 0;
                }

                .card-body {

                    .form-check-label {
                        display: inline-block;
                        width: 85%;
                        vertical-align: top;
                        padding-left: 10px;

                        .store-details {

                            .store-name {
                                font-size: $font-size-14px;
                            }

                            address {
                                font-size: $font-size-14px;
                                font-weight: 300;
                            }
                        }
                    }

                }

                .select-store {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
