#requestGiftBalanceModal {

    .modal-dialog {
        width: 100%;
        margin: 0;

        .modal-content {
            height: 100vh;

            .modal-header {
                border: none;

                .modal-title {
                    text-transform: uppercase;
                    margin: 0 auto;
                }

                button {
                    margin: 0;
                    font-size: 0;
                }
            }

            .giftcard-checkbalance-submit,
            .giftcard-checkbalance-close {
                width: 100%;
            }

            .giftcard-code-value {
                font-weight: 500;
            }

            .giftcard-balance-container {
                background: $very-light-gray;
                margin: 20px 0;
                padding: 20px 0;

                .field {
                    margin: 0;

                    label {
                        text-align: center;
                    }
                }
            }
        }
    }
}
