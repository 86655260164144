.modal-backdrop.show{
    opacity: 0.4;
    z-index: 800;
}

.modal{
    display: none;
    width: 100vw;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
    z-index: 850;

    &-header,
    &-footer {
        padding: 20px 0;
    }

    &-header {
        @include flexbox-center-center();
        justify-content: space-between;
        border-bottom: 1px solid $medium-gray;
        margin-bottom: 20px;
    }

    &-content {
        padding: 0 20px;
        border-radius: 0;
        border: 0 none;

        @include media-breakpoint-up(sm) {
            padding: 0 25px;
        }
    }

    &-footer{
        border-top: 1px solid $medium-gray;
        margin-top: 20px;

        & > *{
            margin: 0;
        }
    }

    &.fade{
        .modal-dialog{
            margin: 30px auto;
            max-width: unset;
            width: 96%;

            @include media-breakpoint-up(md) {
                width: 80%;
            }
            @include media-breakpoint-up(lg) {
                width: 800px;
            }

            &.modal-dialog-centered{
                margin-top: 0;
            }
        }
    }

    &.content-full-width {
        .modal-dialog{
            width: 98% !important;
            max-width: 1400px !important;

            @include media-breakpoint-up(md){
                width: 96% !important;
            }
        }
    }

    &.md-white-close{
        @include media-breakpoint-down(md){
            .close{
                background-image: url('../images/svg/close-white.svg') !important;
            }
        }
    }

    &.return-modal-wrapper{
        .modal-footer{
            padding: 0 10px 30px;
        }
    }

    h1 {
        font-size: $font-size-18px;
        margin: 0;

        @include media-breakpoint-down(lg) { //breakpoint(medium down)
            display: block;
        }
    }
}

.close{
    @include button-as-link($hoverState: false);
    width: 24px;
    height: 24px;
    background: transparent url('../images/svg/close.svg') no-repeat center center;
    background-size: 20px 20px;
    margin-left: auto;

    &.white-close{
        background-image: url('../images/svg/close-white.svg');
    }
}

.modal{
    &.filters-bar-modal,
    &.image-zoom-modal,
    &.franchising-modal{
        background-color: #fff;

        .modal-dialog{
            margin: 0;
            width: 100%;
            height: 100%;
        }

        .modal-content{
            height: 100%;
        }

        .modal-header{
            border-bottom: 0 none;

            .close{
                position: absolute;
                z-index: 1;
                margin: 0;
            }
        }
    }

    &.filters-bar-modal{
        .modal-body {
            padding: 0;
        }

        .modal-header{
            justify-content: center;
            position: relative;
            padding-bottom: 0;

            .close{
                top: 28px;
                right: 0;
                z-index: 1;
                margin: 0;
            }

            h3{
                padding: 0 27px;
            }
        }
    }

    &.image-zoom-modal{
        .modal-content{
            padding: 0;
        }

        .modal-header,
        .modal-footer{
            display: block;
            padding: 0;
            margin: 0;
        }

        .modal-header{
            display: block;

            .close{
                top: 20px;
                right: 20px;
                z-index: 1;
                margin: 0;
            }
        }

        .modal-body{
            img{
                display: none;
                width: 100%;

                &.active{
                    display: inline-block;
                }
            }
        }
    }

    &.franchising-modal{
        .modal-header{
            .close{
                top: 20px;
                right: 20px;
                z-index: 1;
                margin: 0;
            }
        }

        .modal-body{
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;

            .franchising-modal-title{
                text-transform: uppercase;
                margin-bottom: 30px;
                text-align: center;
            }

            button[type="submit"]{
                width: 100%;
            }
        }
    }


    &.lover-card-modal{
        .modal-content{
            padding: 0;
        }

        .modal-header{
            display: block;
            padding: 0;
            margin: 0;
            border: 0 none;

            .close{
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 1;
            }
        }

        .modal-footer{
            display: none;
        }
    }


    &.size-chart-modal{
        .modal-header,
        .modal-footer{
            border: 0 none;
        }

        .modal-header{
            margin-bottom: 0;
            padding-bottom: 5px;
        }

        .modal-footer{
            padding-top: 0;
        }
    }

    &.generic-dialog{
        .modal-dialog{
            @include media-breakpoint-up(lg) {
                width: 600px;
            }
        }

        .modal-header,
        .modal-footer{
            border: 0 none;
        }

        .modal-footer,
        .modal-body{
            text-align: center;
        }

        .modal-header{
            margin-bottom: 0;
        }

        .modal-body{
            font-weight: 300;

            .modal-title{
                font-size: 1.375rem;
                font-weight: 600;
                margin-bottom: 10px;
            }

            .product-to-remove,
            .confirm-message{
                margin-top: 5px;
                font-weight: 400;
            }
        }

        .modal-footer{
            @include flexbox-center-center();
            flex-wrap: wrap;
            margin-top: 0;
            margin-bottom: 10px;

            button + button{
                margin-left: 20px;
            }
        }

    }

    &.edit-wishlist-modal{
        .wishlist-item-update-button-block{
            .btn-update-wishlist-product{
                width: 100%;
            }
        }
    }

    &.notify-me-modal{
        .modal-body{
            .modal-title{
                text-transform: uppercase;
            }

            .field{
                margin-bottom: 10px;
            }

            button{
                width: 100%;
            }
        }

        .modal-footer{
            margin: 0;
            padding: 0 0 25px;

            button{
                margin: 20px auto;
            }
        }
    }

    &.newsletter-modal {
        color: $black;

        .modal-content{
            padding: 0;
        }

        .close{
            margin-right: 25px;
        }

        .modal-header{
            position: absolute;
            z-index: 1;
            width: 100%;

            .close{
                @include media-breakpoint-down(md){
                    background-image: url('../images/svg/close.svg');
                }
            }
        }

        .modal-body{
            .form-container{
                padding: 40px;

                h1,h2,h3,h4{
                    font-size: $font-size-35px;
                    line-height: 1.2;
                    text-transform: uppercase;
                    margin-bottom: 20px;

                    & + p{
                        font-size: $font-size-18px;
                        margin-top: 0;
                        margin-bottom: 30px;
                    }
                }

                button{
                    width: 100%;
                }
            }

            .image-container{
                background-position: center center;
                background-size: cover;

                @include media-breakpoint-down(md){
                    display: none;
                    flex-shrink: 0;
                }

                img{
                    display: inline-block;
                    width: 100%;
                }

                &.newsletter {
                    .content-asset {
                        height: 100%;
                    }
                    .image {
                        background-position: center center;
                        background-size: cover;
                        height: 100%;
                    }
                    img {
                        height: 100%;
                    }
                }
            }

            .subscription-result{
                text-align: center;
                padding: 40px;

                .icon-container{
                    margin: 0 auto 25px;

                    svg{
                        width: 60px;
                        height: 60px;
                    }
                }

                h3,
                h4{
                    line-height: 1.2;
                    margin-bottom: 20px;
                }

                h3{
                    font-size: $font-size-25px;
                }

                h4{
                    font-size: $font-size-18px;
                    font-weight: 500;
                }

                p{
                    margin-bottom: 25px;
                    font-size: $font-size-14px;
                    font-weight: 300;
                }

                button + button{
                    margin-left: 20px;
                }
            }
        }

        &#newsletterModal {
            .modal-dialog {
                @include media-breakpoint-up(lg){
                    width: 65% !important;
                }
            }
            .modal-body{
                padding: 5px;

                .form-container{
                    padding: 20px 30px;

                    h1,h2,h3,h4{
                        margin: 0;

                        & + p {
                            margin-bottom: 15px;
                        }
                    }

                    .privacy-policy {
                        p {
                            font-size: 12px;
                        }
                    }
                    .captcha {
                        label {
                            font-size: 9px;
                        }
                    }

                    .form-actions {
                        margin: 0;
                    }

                    @include media-breakpoint-down(md){
                        h3 {
                            font-size: 26px;
                        }
                        p {
                            font-size: 16px;
                        }

                        .privacy-policy {
                            p {
                                font-size: 11px;
                            }
                        }
                        .captcha {
                            label {
                                font-size: 8px;
                            }
                        }
                    }

                    .field {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
