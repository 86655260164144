$spinner-default-size: 32px;
$spinner-small-size: 16px;

.veil {
    position: absolute;
    z-index: 100;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.fixed{
        position: fixed;
        z-index: $zindex-loader;
        background-color: rgba(0,0,0, 0.3);
    }

    .spinner-border {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: calc(#{$spinner-default-size} / 2 * -1);
        margin-left: calc(#{$spinner-default-size} / 2 * -1);

        &.small{
            margin-top: calc(#{$spinner-small-size} / 2 * -1);
            margin-left: calc(#{$spinner-small-size} / 2 * -1);
        }
    }
}

.modal-content{
    .veil {
        background-color: rgba(0,0,0, 0.15);
    }
}

.spinner-border {
    width: $spinner-default-size;
    height: $spinner-default-size;

    &.small{
        width: $spinner-small-size;
        height: $spinner-small-size;
    }
}
