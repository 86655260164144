/* Cart Page style  */
.page-container.cartpage{
    padding-top: 25px;

    .cart-empty{
        min-height: 250px;

        .page-title{
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .cart-empty-message{
            font-size: initial;
        }

        .cart-empty-login {
            margin: 30px 0 50px;

            p {
                margin-bottom: 50px;

                span{
                    display: block;
                    font-size: $font-size-25px;
                    font-weight: 600;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .page-title{
        font-size: $font-size-25px;
        letter-spacing: 0.4pt;
        margin: 4% 0 0;

        span{
            font-size: 1rem;
            font-weight: 300;
            margin-left: 3px;
            text-transform: lowercase;

            &:before{
                content: "(";
                margin-right: 1px;
            }

            &:after{
                content: ")";
                margin-left: 1px;
            }
        }
    }

    .cart-page{
        font-weight: 300;
        font-size: $font-size-14px;
        margin-bottom: 50px;

        .content-slot {
            margin-top: 20px;
        }

        .products{
            .card{
                padding-top: 20px;
                padding-bottom: 20px;
                border-bottom: none;
            }

            .approaching-discounts {
                .bar {
                    display: inline-flex;
                    align-items: center;
                    gap: 5px;

                    > * {
                        flex-shrink: 0;
                    }

                    span {
                        color: #b0a9a9;
                    }

                    progress {
                        flex-basis: 100%;
                        -webkit-appearance: none;
                        height: 8px;
                        border-radius: 7px;
                    }

                    progress::-webkit-progress-bar {
                        background-color: #e8e2e2;
                        border-radius: 7px;
                    }

                    progress::-webkit-progress-value {
                        background-color: #ab0202;
                        border-radius: 7px;
                    }

                    progress::-moz-progress-bar {
                        background-color: #ab0202;
                    }
                }
            }

            @include media-breakpoint-down(md){
                .approaching-discounts {
                    margin-bottom: 15px;
                }
            }
        }

        .totals,
        .checkout-sidebar-info{
            background-color: $very-light-gray;
            padding: 20px 20px 25px;
        }

        .checkout-sidebar-info {
            margin-top: 20px;

            p {
                &:last-of-type {
                    margin: 0;
                }
            }
        }

        .card{

            &:last-child {
                border-bottom: none;
            }

            .item-details {
                padding-left: 20px;

                .line-item-unit-price{
                    .unit-price-label{
                        display: none;
                    }
                }

                .line-item-total-price{
                    .line-item-price-info{
                        font-weight: 300;
                        margin-right: 7px;

                        &:after {
                            content: ":";
                        }
                    }
                }

                @include media-breakpoint-up(lg){
                    @include flexbox-left-top();
                    flex-wrap: wrap;

                    .line-item-name,
                    .line-item-quantity{
                        width: 70%;
                    }

                    .line-item-unit-price,
                    .line-item-total-price{
                        width: 30%;
                        text-align: right;
                    }

                    .product-line-toolbar{
                        width: 100%;
                    }

                    .line-item-quantity,
                    .line-item-total-price{
                        margin-bottom: 15px;
                    }

                    .item-attributes{
                        width: 100%;
                        margin-bottom: 15px;
                    }

                    .line-item-unit-price{
                        margin-bottom: 0;

                        .price{
                            & > span:last-child{
                                justify-content: flex-end;
                            }

                            .sales{
                                margin-right: 0;
                            }
                        }
                    }

                    .line-item-total-price{
                        justify-content: flex-end;
                        flex-wrap: wrap;
                    }
                }

            }

            .pickup-in-store {
                margin-top: 20px;

                .pickupfrom {
                    padding-top: 20px;

                    .line-item-name {
                        font-weight: bold;
                        display: block;
                    }

                    .store-name,
                    address {
                        font-size: 14px;
                    }
                }
            }
        }

        .totals{
            .coupon-code-container{
                margin-bottom: 15px;

                .coupon-error{
                    color: $alert;
                    font-size: $font-size-14px;

                    .coupon-missing-error{
                        display: none;
                    }
                }

                .promo-code-input{
                    padding-right: 7px;
                }

                label{
                    font-size: 0.625rem;
                    font-weight: 400;
                    text-transform: uppercase;
                    color: #7F7F7F;
                }

                input[type="text"]{
                    width: 100%;
                    height: 100%;
                    line-height: 1;
                }

                button[type="submit"]{
                    width: 100%;
                    padding: 15px;
                }
            }

            .shipping-method-selection{
                .form-group{
                    @include flexbox-left-center();
                    flex-wrap: wrap;
                    justify-content: space-between;

                    select{
                        padding: 5px;
                        margin-top: 10px;
                    }
                }
            }

            .checkout-btn{
                margin-top: 15px;
            }

            .hr{
                margin-top: 15px;
                margin-bottom: 15px;
            }
        }
    }
    .ecs-button{
        margin: 15px 0px;
    }
}
