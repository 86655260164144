html{
    scroll-behavior: smooth;
}

body,
html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    &.disable-scroll {
        overflow: hidden;
    }
}

body {
    font-family: $font-family-primary;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-base;
    color: $black;
    text-rendering: optimizeLegibility;
    background-color: $black;

    &.sb-show-main.sb-main-padded{
        background-color: $white;
    }

    & > .page{
        @include flexbox-left-top($flex-direction: column);
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background-color: $white;
        padding-top: 78px;

        & > *:not(.page-header){
            width: 100%;
            background-color: $white;
        }

        @include media-breakpoint-up(xxl){
            max-width: $page-max-width;
            padding-top: 166.8px;
        }
    }

    .page-container{
        $page-padding-x_mobile: 12px;
        @include set-page-padding-x();

        padding-left: $page-padding-x_mobile;
        padding-right: $page-padding-x_mobile;

        .page-hero-container {
            > div[class^="col"] {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .page-hero-container{
        margin: 15px 0 30px;

        .page-title + p,
        .page-title + .content-asset{
            margin-top: 25px;
        }

        .page-title + .content-asset{
            p:last-child{
                margin-bottom: 0;
            }
        }

        &.with-toolbar{
            margin-bottom: 10px;

            & > .col-12{
                @include flexbox-left-center();
                flex-wrap: wrap;

                .page-title{
                    margin-right: auto;
                    margin-bottom: 20px;
                    padding-right: 30px;
                }

                .form-group{
                    margin-bottom: 20px;

                    select{
                        padding: 5px 10px;
                        margin: 0;
                    }
                }

                .checkbox-field,
                .field,
                .radio-field{
                    margin-top: 0;
                }
            }
        }
    }
}
