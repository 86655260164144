@include media-breakpoint-down(md) { // < 768
    .hide-for-small{
        display: none !important;
        flex-shrink: 0;
    }
}

@include media-breakpoint-up(md) { // >= 768
    .hide-for-medium{
        display: none !important;
        flex-shrink: 0;
    }
}

@include media-breakpoint-down(lg) { // < 1024
    .show-for-large{
        display: none !important;
        flex-shrink: 0;
    }
}

@include media-breakpoint-up(lg) { // >= 1024
    .hide-for-large {
        display: none !important;
        flex-shrink: 0;
    }
}

.d-none {
    display: none !important;
}

.d-md-block {
    @include media-breakpoint-up(md){
        display: block !important;
    }
}
