/*
Common style: Minicart, Cart Page, Checkout
*/
.cart .card,
.product-summary-block,
.order-list-container,
.wishlistItemCards .card{
    .item-image{
        img{
            max-width: 70px;

            @include media-breakpoint-up(sm){
                max-width: 115px;
            }
        }
    }

    .line-item-name,
    .item-attributes,
    .line-item-quantity{
        font-weight: 300;
    }

    .line-item-name,
    .line-item-unit-price,
    .line-item-total-price,
    .line-item-quantity{
        font-size: $font-size-14px;
    }

    .line-item-name,
    .line-item-unit-price,
    .line-item-total-price,
    .item-attributes,
    .line-item-quantity,
    .line-item-promo{
        margin-bottom: 10px;
    }

    .line-item-name{
        margin-top: -2px;
    }

    .line-item-total-price{
        //display: none;
        @include flexbox-left-center();

        .line-item-total-text{
            font-weight: 300;
            &:after{
                content: ":"
            }
            margin-right: 3px;
        }

        & > .price{
            @include flexbox-left-center();

            .strike-through{
                margin-right: 20px;

                &.non-adjusted-price{
                    display: none;
                }
            }

            .line-item-total-price-amount{
                font-weight: 700;
                letter-spacing: 0.4pt;
            }
        }
    }

    .line-item-unit-price{
        .line-item-total-text{
            display: none;
        }

        .price{
            .percentage{
                display: none;
                position: unset;
                color: $black;
                background-color: transparent;
                width: auto;
                line-height: inherit;
                margin-right: 20px;
            }
        }
    }

    .item-attributes{
        .line-item-attributes{
            margin-bottom: 0;
        }

        .line-item-attributes + .line-item-attributes{
            margin-top: 2px;
        }

        .line-item-availability{
            display: none;
        }
    }

    .line-item-quantity{
        select{
            border-color: transparent !important;
            padding: 0
        }
    }

    /* .line-item-promo{

    } */

    .product-line-toolbar{
        @include flexbox-right-center();

        .icon-button{
            svg{
                width: 20px;
                height: 20px;
            }
        }

        button + button{
            margin-left: 20px;
        }
    }
}

.cart .card,
.product-summary-block .product-line-item,
.order-list-container .order-item,
.wishlistItemCards .card{
    border-bottom: 1px solid $medium-gray;

    &:last-child{
        border-bottom: 0 none;
    }
}

.cart .card,
.product-summary-block .product-line-item{
    padding-top: 15px;
    padding-bottom: 15px;
}

.order-list-container .order-item,
.wishlistItemCards .card{
    padding-top: 20px;
    padding-bottom: 20px;
}
