.with-icon{
    @include flexbox-left-center();

    & > svg{
        height: 16px;
        max-width: 16px;
        margin-right: 5px;
    }

    & > span{

    }
}
