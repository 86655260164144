/* wg30: tall image, image sx, text dx
   wg31: image as background, image dx, text sx
   wg32: image top, text bottom
   wg33: image as background, image sx, text dx
   wg34: image top, text bottom
   wg35: image top, text bottom
*/

.wg30,
.wg31,
.wg32,
.wg33,
.wg34,
.wg35,
.wg36
{
    .wg-text-box {
        h2, .h2,
        h3, .h3{
            span{
                text-transform: none;
                font-size: 1rem;
                margin-top: 12px;
            }
        }
    }

    [data-link] {
        cursor: pointer;
    }

    [data-link=""] {
        cursor: inherit;
    }
}

.wg32{
    & > article{
        margin-bottom: 40px;

        @include media-breakpoint-up(md){
            margin-bottom: 20px;
        }

        img {
            width: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.image-full-width{
        & > article{
            img {
                width: 100%;
            }
        }
    }

    &.border-top-bottom{
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
    }

    &.border-top{
        border-top: 1px solid $light-gray;
    }

    &.border-bottom{
        border-bottom: 1px solid $light-gray;
    }

    &.border-top-bottom,
    &.border-top{
        padding-top: 40px;
    }

    &.border-top-bottom,
    &.border-bottom{
        @include media-breakpoint-down(md){
            &:last-child{
                margin-bottom: 20px;
            }
        }

        padding-bottom: 20px;
    }
}

.wg33,
.wg31,
.wg30{
    .wg-text-box {
        margin: 0;
        padding: 30px 20px 35px;
        @include media-breakpoint-up(md){
            padding: 30px;
        }

        p {
            & + .button{
                margin-top: 25px;
            }
        }
    }
}

.wg33,
.wg31,
.wg30{
    .row{
        & > .content-container{
            //background-color: #F4F4F4;
            @include flexbox-center-center();
        }
    }
}

.wg33,
.wg31{
    @include set-page-padding-x();

    .wg-text-box {
        @include media-breakpoint-up(md){
            max-width: 550px;
        }

        h1, .h1,
        h2, .h2,
        h3, .h3,
        h4, .h4,
        h5, .h5,
        h6, .h6{
            & + p,
            & + .button{
                margin-top: 5%;
            }
        }
    }

    .row{
        & > .image-container{
            background-position: center center;
            background-size: cover;
            @include widget_image-as-background_height();
        }

        & > .content-container{
            @include media-breakpoint-down(md){
                justify-content: flex-start;
            }
        }
    }
}

.wg31{
    @include media-breakpoint-down(md){
        .row{
            & > .image-container{
                order: 1;
            }

            & > .content-container{
                order: 2;
            }
        }
    }
}

.wg30{
    .wg-text-box {
        @include media-breakpoint-up(md){
            max-width: 460px;

            h1, .h1,
            h2, .h2,
            h3, .h3,
            h4, .h4,
            h5, .h5,
            h6, .h6{
                & + p,
                & + .button{
                    margin-top: 7%;
                }
            }
        }
    }
}

.wg34,
.wg35{
    & > article{
        width: 100%;
        flex: 0 0 auto;
        @include media-breakpoint-up(md){
            margin-top: 0 !important;
        }

        & + article{
            margin-top: 40px;
        }
    }

    .wg-text-box {
        h2, .h2,
        h3, .h3,
        h4, .h4,
        h5, .h5,
        h6, .h6{
            text-transform: none;
            font-weight: 600;
        }
    }
}

.wg34{
    & > article{
        @include media-breakpoint-up(md){
            &:first-child{
                width: 66.66666667%;
            }

            &:last-child{
                flex: 1 0 0%;
            }
        }
    }
}

.wg35{
    & > article{
        @include media-breakpoint-up(md){
            &:nth-child(1),
            &:nth-child(2){
                width: 50%;
            }

            &:nth-child(3){
                width: 100%;
                margin-top: 40px !important;
            }
        }

        @include media-breakpoint-up(lg){
            &:nth-child(1),
            &:nth-child(2){
                width: 25%;
            }

            &:nth-child(3){
                width: 50%;
                margin-top: 0 !important;
            }
        }
    }
}

.wg36{
    &  > .row{
        padding: 20px;
    }

    .wg-text-box {
        margin-top: 0;
        padding-right: 25px;
    }

    .image-container{
        @include flexbox-left-center();
    }
}

.modal-body{
    .wg33,
    .wg31{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
