.error-page {
    background-color: $white;
    padding: 25px 0;

    > .row {
        margin-inline: 0;
    }

    .error-message {
        padding: 15px 0 50px;
    }

    pre {
        white-space: unset;
        padding-bottom: 25px;
    }

    .error-container {
        svg {
            margin-top: 70px;
            margin-bottom: 40px;
        }
    }

    .no-result-error {
        margin-top: 60px;
    }

    h1 {
        font-size: $font-size-25px;
        font-weight: 600;
    }

    h2 {
        font-size: 1rem;
        font-weight: 300;
    }

    .continue-shopping {
        margin-bottom: 150px;

        &.noresult {
            margin-bottom: 85px;
        }
    }

    .products-carousel {
        h2 {
            font-size: $font-size-35px;
            font-weight: 700;
        }
    }

    .faq-row {
        .wg-accordion {
            .widget-title {
                text-align: left;
                font-size: $font-size-25px;
                margin-bottom: 12px;

                h2 {
                    font-size: inherit;
                    font-weight: bold;
                }
            }
        }
    }
}
