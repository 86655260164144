$productTile-padding-x: 18px;

.search-banner,
.search-results {
    .toolbar {
        @include flexbox-left-center();

        .viewmode-3x,
        .viewmode-4x {
            opacity: 0.2;

            svg {
                width: 25px;
                height: 25px;

                @include media-breakpoint-down(sm) {
                    &.viewmode-small-screen {
                        display: inline-block;
                    }

                    &.viewmode-large-screen {
                        display: none;
                    }
                }

                @include media-breakpoint-up(sm) {
                    &.viewmode-small-screen {
                        display: none;
                    }

                    &.viewmode-large-screen {
                        display: inline-block;
                    }
                }
            }

            &.selected {
                opacity: 1;
            }
        }

        .viewmode-4x {
            margin: 0 10px;
        }

        .filter-results {
            margin-left: 20px;
        }
    }
}

.search-banner {
    margin: 15px 0;

    .toolbar {
        margin-top: 22px;
        //margin-bottom: 17px;
    }
}

.search-banner,
.no-results-product {
    .category-description {
        margin-top: 15px;

        @include media-breakpoint-up(lg) {
            text-align: right;
            padding-left: 30px;
            margin-top: 0;
        }

        p {
            margin: 0;
            height: 45px;
            overflow: hidden;
        }

        button {
            font-size: $font-size-14px;
            margin-top: 20px;
        }

        &.expanded {
            p {
                height: auto;
            }

            button {
                margin-top: 15px;
            }
        }
    }
}

.no-results-product {
    .category-description {
        text-align: center;
        padding-left: 0;
        margin-bottom: 30px;

        button {
            margin-top: 10px;
        }
    }
}

.search-results {
    .toolbar-container,
    .search-nav {
        @include set-page-padding-x();
        margin-bottom: 10px;
        margin-bottom: 10px;
    }

    .product-grid {
        @include media-breakpoint-down(sm) {
            &.row-cols-3 > * {
                flex: 0 0 auto;
                width: 100%;
            }

            &.row-cols-4 > * {
                flex: 0 0 auto;
                width: 50%;
            }
        }

        @include media-breakpoint-up(sm) {
            padding-left: calc(#{$page-padding-x} - #{$productTile-padding-x});
            padding-right: calc(#{$page-padding-x} - #{$productTile-padding-x});

            &.row-cols-1 > * {
                flex: 0 0 auto;
                width: 33.3333333333%;
            }

            &.row-cols-2 > * {
                flex: 0 0 auto;
                width: 25%;
            }
        }

        & > div.col:not(.grid-footer) {
            padding: 15px $productTile-padding-x;

            &.category-widget {
                .wg {
                    padding-left: 0;
                    padding-right: 0;

                    & > article,
                    &.row-padding-x > * {
                        margin-bottom: 0;
                        padding-right: 0;
                        padding-left: 0;
                    }

                    .wg-text-box {
                        margin-top: 13px;

                        h2,
                        h3,
                        .h2,
                        .h3 {
                            font-size: $font-size-14px;
                            font-weight: 700;
                            letter-spacing: 0.4pt;
                        }

                        p {
                            font-size: $font-size-14px;
                            height: 41px;
                            overflow: hidden;
                        }

                        p + .button {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }

        .grid-footer {
            margin-bottom: 40px;

            &.veiled {
                .show-more {
                    .show-more-button {
                        visibility: hidden;
                    }
                }
            }

            .show-more {
                margin: 25px auto 0;
                text-align: center;
            }

            .pagination {
                border-radius: 0;
                margin-top: 25px;

                ol {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin: 0 auto;
                    list-style: none;
                    padding-left: 0;

                    li {
                        height: 32px;
                        background-color: transparent;

                        &.selected {
                            font-weight: 600;
                            text-decoration: underline;
                        }

                        &.go-to__prev-page {
                            margin: 0 10px 0 0;
                        }

                        &.go-to__next-page {
                            margin: 0 0 0 10px;
                        }

                        &::marker {
                            display: none;
                        }

                        &.go-to {
                            margin: 0 5px;

                            button {
                                &:disabled {
                                    cursor: not-allowed;
                                }
                            }

                            &.ellipsis {
                                width: auto;

                                span {
                                    position: relative;
                                    top: 8px;
                                }
                            }
                        }

                        button {
                            padding: 0;
                            background-color: transparent;
                            border: 0;
                        }

                        a,
                        button {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100%;
                            font-size: inherit;
                            line-height: inherit;
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    // FAQ
    .wg-accordion {
        margin-bottom: 60px;

        .widget-title {
            text-align: left;
            font-size: $font-size-25px;
            margin-bottom: 12px;

            h2,
            h3,
            .h2,
            .h3 {
                font-size: inherit;
            }
        }
    }
}

.refinement-bar {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .accordion-item {
        .accordion-button {
            padding: 15px 0;
            text-transform: uppercase;
            font-size: $font-size-14px;
            font-weight: 600;
            letter-spacing: 0.4pt;
        }

        .accordion-body {
            padding: 0px 10px 25px;

            ul {
                li {
                    &.size-attribute {
                        button {
                            text-transform: uppercase;
                        }
                    }

                    &.category-attribute {
                        ul {
                            padding-left: 10px;
                            margin-top: 7px;
                        }
                    }

                    &:not(.color-attribute) {
                        button {
                            padding-left: 29px;
                        }
                    }

                    button {
                        cursor: pointer;
                        position: relative;
                        font-weight: 400;

                        span.color-value {
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            outline: $light-gray solid 1px;
                            outline-offset: 2px;
                        }

                        span:not(.sr-only, .color-value) {
                            &:before,
                            &:after {
                                content: "";
                                position: absolute;
                            }

                            &:before {
                                top: 0;
                                left: 0;
                                width: 20px;
                                height: 20px;
                                border: 1px solid $black;
                            }

                            &:after {
                                top: 5px;
                                left: 5px;
                                width: 10px;
                                height: 10px;
                                background-color: $black;
                                opacity: 0;
                                transform: scale3d(0, 0, 0);
                                transition: transform 0.3s
                                        cubic-bezier(0.77, 0.2, 0.05, 1),
                                    opacity 0.3s
                                        cubic-bezier(0.77, 0.2, 0.05, 1);
                            }
                        }

                        &.selected {
                            span.color-value {
                                outline-color: $black;
                            }

                            span:not(.sr-only, .color-value) {
                                &:after {
                                    opacity: 1;
                                    transform: scale3d(1, 1, 1);
                                    transition: transform 0.3s
                                            cubic-bezier(0.77, 0.2, 0.05, 1),
                                        opacity 0.3s
                                            cubic-bezier(0.77, 0.2, 0.05, 1);
                                }
                            }
                        }
                    }
                }

                li + li {
                    margin-top: 7px;
                }
            }
        }

        &.refinement-colour {
            .accordion-body {
                ul {
                    @include flexbox-left-center();
                    flex-wrap: wrap;

                    li {
                        margin: 7px 0;

                        &:first-child {
                            margin-right: 15px;
                        }
                    }

                    li + li {
                        margin-right: 15px;
                    }
                }
            }
        }
    }

    .filter-footer {
        margin-top: 20px;
        margin-bottom: 40px;
        @include flexbox-center-center($flex-direction: column);

        button {
            width: 100%;

            &.reset {
                order: 2;
                margin-top: 30px;
            }

            &.apply-filters {
                order: 1;
            }
        }

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            justify-content: space-between;

            button {
                &.reset {
                    order: 1;
                    margin-top: 0;
                }

                &.apply-filters {
                    order: 2;
                }
            }

            button + button {
                margin-left: 30px;
                margin-top: 0;
            }
        }
    }
}

.category-landing-page-header {
    padding-top: 50px;
    padding-bottom: 50px;
}

.category-item-link {
    display: block;
    margin-bottom: 20px;
}

.category-landing-page-grid-categories {
    display: grid;
    grid-template: auto / repeat(1, 1fr);
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;

    @include media-breakpoint-up(lg) {
        grid-template: auto / repeat(2, 1fr);
        gap: 50px;
    }
}

.category-landing-page-description {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
}

.linked-categories {
    .slick-track,
    .slick-slider {
        display: flex;
    }

    .slick-slide {
        @include media-breakpoint-down(lg) {
            min-width: min-content;
        }

        // stylelint-disable-next-line
        a {
            font-size: 14px;
            @include media-breakpoint-down(lg) {
                font-size: 13px;
            }
            text-decoration: none;
            text-transform: uppercase;
            display: grid;
            align-items: center;
            text-align: center;
            padding-left: 15px;
            padding-right: 15px;
            height: 100%;
        }

        &.image {
            color: #ccc;

            img {
                margin-bottom: 10px;
                width: 100%;
                border-radius: 10px;
            }

            &:hover {
                color: #000;
                cursor: pointer;

                img {
                    border: 1px solid #000;
                }
            }

            @include media-breakpoint-down(lg) {
                color: #000;
            }
        }

        &:not(.image) {
            height: auto;
            min-height: 50px;
            border: 1px solid #000;
            border-radius: 20px;
            margin: 10px;
            padding: 0;
            word-wrap: break-word;

            &:hover {
                cursor: pointer;
                background-color: #f2f2f2;
            }
        }
    }
}

.related-categories {
    text-transform: uppercase;
    margin-bottom: 50px;

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

    .related-category-body {
        display: grid;
        font-size: 14px;
        gap: 20px;
        grid-template-columns: repeat(7, 1fr);

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(4, 1fr);
        }
        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
