footer{
    margin-top: auto;

    .footer-container{
        & > div{
            padding-left: $page-padding-x_mobile;
            padding-right: $page-padding-x_mobile;
        }

        @include media-breakpoint-up(sm){
            & > div{
                padding-left: $page-padding-x;
                padding-right: $page-padding-x;
            }
        }

        ul.icons-list{
            @include resetUl();

            li{
                padding: 2px;
                svg{
                    width: 25px;
                    height: 25px;
                }
            }
        }

        ul.icons-list:not(.social-list){
            @include flexbox-center-center();

            @include media-breakpoint-up(lg){
                justify-content: flex-end;
            }
        }

        .footer-servicies{
            font-size: $font-size-14px;
            background-color: $very-light-gray;
            padding-top: 12px;
            padding-bottom: 12px;
            letter-spacing: 0.3pt;

            @include media-breakpoint-up(lg) {
                flex-wrap: nowrap;
            }

            & > div[class^="col"]{
                text-align: center;
                padding-top: 10px;
                padding-bottom: 10px;

                svg{
                    height: 30px;
                    max-width: 35px;
                }

                span{
                    display: block;
                    margin-top: 10px;
                    font-weight: 600;
                }
            }
        }

        .newsletter_social{
            color: #fff;
            background-color: $black;
            padding-top: 15px;
            padding-bottom: 15px;

            .nl-subscription,
            .social-container{
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .nl-subscription{
                .input-group{
                    @include flexbox();
                    flex-wrap: wrap;

                    @include media-breakpoint-up(lg){
                        flex-wrap: nowrap;
                    }

                    .label{
                        @include flexbox-left-center();
                        font-size: 1rem;
                        width: 100%;
                        flex-basis: 100%;
                        margin-bottom: 10px;
                        text-transform: unset;
                        color: $white;

                        @include media-breakpoint-up(lg){
                            font-size: $font-size-18px;
                            margin-right: 20px;
                            margin-bottom: 0;
                            width: auto;
                            flex-basis: auto;
                        }
                    }

                    input,
                    button{
                        width: 100%;
                    }

                    input{
                        font-family: $font-family-primary;
                        font-size: $font-size-14px;
                        color: $white;
                        line-height: $line-height-base;
                        border: 1px solid $white;
                        background-color: $black;
                        padding: 15px;
                        max-width: 300px;
                    }

                    button{
                        max-width: 170px;
                    }

                    @include media-breakpoint-down(sm){
                        input{
                            width: 60%;
                        }

                        button{
                            width: 40%;
                        }
                    }

                    input:-ms-input-placeholder,
                    input::placeholder{
                        color: $white !important;
                    }
                }
            }

            .social-container{
                .social-list{
                    @include media-breakpoint-up(lg){
                        @include flexbox-right-center();
                        margin-left: auto;
                    }

                    li{
                        flex-basis: auto;
                    }

                    li + li{
                        margin-left: 25%;
                    }
                }
            }
        }

        .footer-links{
            //padding: 25px $page-padding-x_mobile;
            padding-top: 25px;
            padding-bottom: 25px;

            .footer-item{
                font-size: $font-size-14px;

                .footer-item-collapse {
                    border-bottom: 1px solid #CCCCCC;

                    @include media-breakpoint-up(lg){
                        border-bottom: none;
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }

                h3,
                button{
                    font-size: inherit;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    font-weight: 600;
                }

                ul.collapsible{
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li{
                        margin: 10px 0;
                        font-weight: 300;
                    }
                }

                @include media-breakpoint-down(lg) {
                    border-bottom: 1px solid $medium-gray;

                    &.expanded,
                    .expanded {
                        button{
                            &:after{
                                content: '-';
                            }
                        }
                    }

                    button{
                        margin-top: 12px;
                        width: 100%;
                        text-align: left;
                        position: relative;

                        &:after{
                            content: '+';
                            position: absolute;
                            right: 0;
                            top: 0;
                            font-size: $font-size-18px;
                            font-weight: 300;
                            padding-left: 7px;
                        }
                    }

                    ul.collapsible:not(.show){
                        display: none;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                .footer-item{
                    padding: 0 10px;
                }

                .footer-item{
                    @include flexbox-top-center();

                    .content-asset{
                        width: auto;
                    }
                }

            }
        }

        .copyright_payments{
            font-size: $font-size-12px;
            color: #fff;
            background-color: $black;

            p{
                margin: 0;
                padding: 0;
            }

            .copyright,
            .payments{
                padding-top: 10px;
                padding-bottom: 10px;

                .icons-list {
                    flex-wrap: wrap;
                }
            }

            .copyright{
                @include flexbox-center-center();

                @include media-breakpoint-up(lg) {
                    justify-content: flex-start;
                }
            }

            .payments{
                ul.icons-list{
                    li{
                        svg{
                            width: 42px;
                        }
                    }
                }
            }
        }

        /* Checkout Footer */
        &.checkout{
            .policy-links{
                background-color: $black;
                padding: 30px 0;

                .content-asset{
                    @include flexbox-center-center($flex-direction: column);

                    @include media-breakpoint-up(lg){
                        flex-direction: row;
                    }
                }

                a{
                    margin: 7px 0;
                    font-size: $font-size-14px;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: $white;
                }

                @include media-breakpoint-up(lg){
                    a + a{
                        &:before{
                            content: "/";
                            font-size: inherit;
                            margin-left: 10px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}
