.sizing-information{
    .title,
    .subtitle{
        letter-spacing: 0.4pt;
    }

    .title{
        text-transform: uppercase;
        font-size: $font-size-25px;
        text-align: center;
        margin-bottom: 20px;
    }

    .subtitle{
        font-size: $font-size-18px;
        margin-bottom: 0;
    }

    .tips{
        //margin-top: 40px;
        font-weight: 300;

        .title{
            text-transform: unset;
            font-weight: 600;
            text-align: left;
            margin-bottom: 10px;
        }

        ul, ol{
            li + li{
                margin-top: 15px;
            }
        }

        strong{
            font-weight: 500;
        }
    }

    table{
        color: $black;
        margin-bottom: 40px;
        border-color: $medium-gray;

        th{
            font-weight: 700;
            text-align: center;

            &:first-child{
                font-weight: 400;
                text-align: left;
            }
        }

        td{
            font-size: $font-size-14px;
            text-align: center;
            font-weight: 300;

            &:first-child{
                text-transform: uppercase;
                text-align: left;
                font-weight: 400;
            }
        }
    }
}
