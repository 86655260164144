.locale-container {
    .locale-country{
        @include popover(
            $color: $black,
            $left: auto,
            $right: 0,
            $min-width: 93px
        );
    }

    .locale-language{
        display: none;

        @include popover(
            $color: $black,
            $left: auto,
            $right: 0,
            $min-width: 80px
        );
    }

    .locale-country,
    .locale-language{
        & > a{
            &:hover{
                text-decoration: none;

                svg.angle-icon{
                    transform: rotate(180deg);
                    margin-bottom: 2px;
                }
            }

            svg.angle-icon{
                width: 10px;
                margin-left: 2px;
                margin-right: 0;
            }
        }
    }
}

.header-banner-container{
    .locale-container {
        display: none;

        @include media-breakpoint-up(lg) {
            @include flexbox-right-center();
            flex-shrink: 0;
            position: absolute;
            right: 0;
            width: $locale-container-width;

            .locale-country,
            .locale-language{
                & > a,
                & > a:hover{
                    color: $white;
                }
            }

            .locale-country{
                margin-left: 15px;

                .black-icon{
                    display: none;
                }
            }

            .locale-language{
                margin-left: 30px;
            }
        }
    }
}

nav .menu-group ul.nav li.nav-item.links{
    .locale-container {
        display: none;

        .locale-country {
            .popover{
                left: 0;
                right: auto;
                min-width: 100%;
            }
        }

        @include media-breakpoint-down(lg) {
            display: inline-block;

            .locale-country{
                & > a{
                    padding: 7px 0;
                    margin-bottom: 10px;

                    .white-icon{
                        display: none;
                    }
                }
            }
        }
    }
}
