$nav-mobile-head-height: 40px;

nav{
    @include media-breakpoint-down(lg){
        position: fixed;
        left: 0;
        top: $header-height_mobile;
        transform: translateX(-100%);
        width: 100%;
        height: calc(100vh - #{$header-height_mobile});
        overflow: hidden;
        background-color: rgba(0,0,0,0);
        z-index: $zindex-navbar-mobile;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background-color 0.2s cubic-bezier(0.77,0.2,0.05,1.0);
    }

    @include media-breakpoint-up(lg){
        margin-top: 10px;
    }

    .menu-group{
        position: relative;
        @include media-breakpoint-down(lg){
            width: $navbar-width-mobile;
            height: 100%;
            background-color: $white;
        }

        ul{
            @include resetUl();
        }

        ul.nav{
            li.nav-item{
                border-bottom: 1px solid $gray-eee;

                @include media-breakpoint-down(lg){
                    padding: 0 15px;
                }

                a{
                    display: block;
                    padding: 13px 0;
                    &.has-icon {
                        @include media-breakpoint-down(lg){
                            padding: 10px 0;
                        }
                    }
                    font-size: $font-size-14px;
                    line-height: 1.2;

                    @include media-breakpoint-up(lg){
                        font-weight: 600;
                        padding: 10px 0;
                    }

                    &.has-icon {
                        display: flex;
                        align-items: center;

                        .category-icon {
                            display: block;
                            margin-right: 6px;

                            img {
                                display: block;
                            }
                        }
                    }
                }

                & > a{
                    text-decoration: none !important;
                    text-transform: uppercase;
                }

                /* temporary fix */
                &.level-top{
                    & > .submenu-container{
                        li{
                            .submenu-container{
                                display: none;
                            }
                        }
                    }
                }

                &.links{
                    border-top: 2px solid $black;
                    border-bottom: 2px solid $black;

                    & > a{
                        text-transform: unset;
                        padding: 7px 0;
                        margin-top: 10px;
                        margin-bottom: 10px;

                        &:hover{
                            text-decoration: underline !important;
                        }
                    }
                }

                &.customer{
                    & > a{
                        font-weight: 700;
                        letter-spacing: 0.3pt;
                    }
                }
            }

            li.no-item{
                &.title{
                    display: block;
                    padding: 10px 0;
                    text-transform: uppercase;
                    font-weight: 700;
                    letter-spacing: 0.3pt;
                }
            }

            @include media-breakpoint-down(lg){
                @include flexbox-left-top($flex-direction: column);

                li{
                    width: 100%;

                    &.level-top{
                        & > .submenu-container{
                                top: 0;
                                transform: translateX(-$navbar-width-mobile);
                                transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
                                overflow-y: scroll;

                                .submenu-item {
                                    &.mobile-only {
                                        display: block;
                                    }
                                }
                        }

                        &.expanded{
                            & > a{
                                height: $nav-mobile-head-height;
                            }

                            & > .submenu-container{
                                    transform: translateX(0);
                                    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

                                    & > .go-to-back{
                                        display: inline-block;
                                    }
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: center;
                align-content: stretch;

                li.level-top {
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    cursor: pointer;

                    border-bottom: 0 none;
                    & > a{
                        display: flex;
                        align-items: center;
                        height: 100%;
                        border: 1px solid transparent;
                    }

                    &:not(.expanded) {
                        .submenu-container {
                            opacity: 0;
                            pointer-events: none;
                        }
                    }

                    &.expanded {
                        > a {
                            text-decoration: none;
                            border-bottom-color: $black;
                        }

                        .submenu-container {
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }

                    .submenu-container {
                        .submenu-item {
                            &.mobile-only {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .submenu-container{
            width: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            background-color: $white;
            cursor: initial;
            z-index: 1;

            ul.submenu {
                @include flexbox-left-top($flex-direction: column);

                li {
                    @include media-breakpoint-down(lg) {
                        padding: 0 15px;

                        &.no-item {
                            padding: 10px 15px;
                        }
                    }
                    a {
                        font-size: 0.938rem;
                        font-weight: 300;
                    }
                }
            }

            @include media-breakpoint-up(xxl) {
                max-width: $page-max-width;
            }

            @include media-breakpoint-up(lg) {
                @include flexbox-top-center();

                padding-top: 20px;
                padding-bottom: 20px;
                top: 100%;
                transition: opacity 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);

                ul.submenu {
                    li {
                        a {
                            padding: 5px 0;
                        }
                    }
                }
            }

            .go-to-back{
                display: none;

                @include media-breakpoint-down(lg) {
                    padding: 0 15px;
                }

                button{
                    text-transform: uppercase;
                    margin: 5px 0 17px;
                    font-weight: 400;

                    &:hover,
                    &:focus{
                        span{
                            border-bottom: 0 none;
                        }
                    }
                }
            }
        }

        .editorial-menu-container{
            margin-top: 15px;

            @include media-breakpoint-up(lg){
                margin-left: 3%;
                margin-top: 0;
            }

            @include media-breakpoint-down(lg) {
                padding: 0 15px;
            }

            ul.editorial-menu{
                li{
                    span{
                        display: block;
                        text-transform: uppercase;
                    }

                    img{
                        display: none
                    }
                }

                @include media-breakpoint-up(lg){
                    @include flexbox-left-center();
                    max-width: 920px;
                    flex-basis: 25%;

                    li{
                        //width: 25%;
                        //padding: 0 15px;

                        span{
                            margin-bottom: 10px;
                        }

                        img{
                            display: inline-block;
                            max-width: 100%
                        }
                    }

                    li +li{
                        //margin-left: 3%;
                        margin-left: 25px;
                    }
                }
            }
        }
    }
}


body.nav-open{
    @include media-breakpoint-down(lg){
        nav{
            left: 0;
            transform: translateX(0);
            background-color: rgba(0,0,0,0.5);
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background-color 1.3s cubic-bezier(0.77,0.2,0.05,1.0);
        }
    }
}