$black: #000000;
$dark-gray: #333;
$darker-gray: #979797;
$medium-gray: #ccc;
$light-gray: #D8D8D8;
$very-light-gray: #F2F2F2;
$gray-eee: #eeeeee;
$gray-e6: #E6E6E6;
$white: #FFFFFF;

$disabled-color: $dark-gray;
$outline-color: $darker-gray;

$btn-palette_white-text: (
    "black": #000,
    "alert": #FF0000
);

$btn-palette_black-text: (
    "white": #FFFFFF
);
