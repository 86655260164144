$spacer-default-size: 40px;
$spacer-small-size: 20px;
$spacer-medium-size: 30px;
$spacer-large-size: 60px;
$spacer-huge-size: 80px;

.spacer {
    width: 100%;
    height: $spacer-default-size;

    &.small {
        height: $spacer-small-size;
    }

    &.medium {
        height: $spacer-medium-size;
    }

    &.large {
        height: $spacer-large-size;
    }

    &.huge {
        height: $spacer-huge-size;
    }
}

.row-padding-x{
    padding-left: $page-padding-x_mobile;
    padding-right: $page-padding-x_mobile;

    @include media-breakpoint-up(sm) {
        padding-left: calc(#{$page-padding-x} - #{$padding-tile-x});
        padding-right: calc(#{$page-padding-x} - #{$padding-tile-x});

        & > .col,
        & > * {
            padding-left: $padding-tile-x;
            padding-right: $padding-tile-x;
        }

        .slider:not(.carousel){
            .slick-arrow{
                &.slick-prev{
                    left: calc(#{$padding-tile-x} - 1px);
                }

                &.slick-next{
                    right: calc(#{$padding-tile-x} - 1px);
                }
            }
        }
    }
}
