@import "../components/checkout-steps";

.checkoutpage{
    margin-bottom: 20px;

    .need-help{
        .text-center{
            text-align: center;
            font-size: $font-size-12px;
            font-weight: 700;
            letter-spacing: 0.4pt;
            text-transform: uppercase;
            margin-bottom: 12px;
        }
    }

    .checkout-steps{
        @include media-breakpoint-up(md){
            padding-right: 30px;
        }

        .error-message{
            color: red;

            p:empty{
                margin-bottom: 0;
            }
        }

        .radio-field,
        .checkbox-field{
            margin-bottom: 0;
        }
    }

    .checkout-summary{
        font-weight: 300;
        font-size: $font-size-14px;

        .order-total-container,
        .order-product-container,
        .checkout-sidebar-info,
        .giftcard-total-container {
            margin-bottom: 20px;
        }

        .checkout-sidebar-info-content {
            background-color: $very-light-gray;
            padding: 25px 25px 30px;
        }

        .order-total-container{
            .order-total-summary{
                background-color: $very-light-gray;
                padding: 25px 25px 30px;
                .grand-total-container{
                    margin-bottom: 0;
                }

                .hr{
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
        }

        .giftcard-total-container {

            &.hidden-giftcard-total-container {
                display: none;
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .order-product-container{
            .order-product-summary{
                background-color: $very-light-gray;
                padding: 25px 25px 30px;
                .product-summary-header{
                    @include flexbox-left-center();
                    justify-content: space-between;
                    font-size: 1rem;
                    margin-bottom: 5px;

                    span + span{
                        padding-left: 15px;
                    }

                    .grand-total-price{
                        font-weight: 700;
                        letter-spacing: 0.4pt;
                    }
                }

                .product-line-item{
                    &:last-child{
                        padding-bottom: 0;
                        border-bottom: 0 none;
                    }

                    .item-details{
                        & > *:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .next-step-button{
        padding-top: 25px;
        padding-bottom: 25px;

        button{
            width: 100%;
        }
    }

    .ghost {
        opacity: 0.5;
    }

    .data-checkout-stage {
        &.multi-ship{
            .single-shipping .shipping-content {
                display: none;
            }

            .shipping-summary .single-shipping {
                display: none;
            }

            .confirm-details .single-shipping {
                display: none;
            }
        }


        /* CHECKOUT STEPS */

        // Initial states ------------------------
        &[data-checkout-stage] {
            .customer-summary,
            .payment-summary,
            .shipping-summary{
                display: none;
            }

            .next-step-button{
                button.submit-customer,
                button.submit-shipping,
                button.submit-payment,
                button.place-order {
                    display: none;
                }

                .privacy-info{
                    display: none;

                    a{
                        text-decoration: underline;

                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        // Customer ------------------------------
        //&[data-checkout-stage=undefined],
        &[data-checkout-stage=customer] {
            .checkout-summary{
                display: none;
                flex-shrink: 0;
            }

            .checkout-steps{
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                max-width: 640px;

                .step-shipping,
                .step-payment{
                    display: none;
                }
            }

            .ghost.customer,
            .customer-summary,
            .need-help{
                display: none;
            }

            /* .shipping-section {
                display: none;
            }

            .payment-form {
                display: none;
            }
            */

            .next-step-button{
                button.submit-customer {
                    display: inline-block;
                }
            }
        }

        // Shipping ------------------------------
        &[data-checkout-stage=shipping] {
            .customer-section,
            .create-account,
            .continue-as-guest {
                display: none;
            }

            .ghost.customer,
            .ghost.shipping {
                display: none;
            }

            &.multi-ship .order-product-summary {
                display: none;
            }

            .payment-form {
                display: none;
            }

            .next-step-button{
                button.submit-shipping {
                    display: inline-block;
                }
            }

            [data-address-mode=customer] {
                .shipping-address-block {
                    display: none;
                }
            }

            [data-address-mode=shipment] {
                .shipping-address-form {
                    display: none;
                }
            }

            [data-address-mode=edit] {
                .shipping-address-block {
                    display: none;
                }
            }

            [data-address-mode=new] {
                .btn-show-details,
                .btn-add-new {
                    display: none;
                }
            }
        }

        // Payment -------------------------------
        &[data-checkout-stage=payment] {
/*             button.submit-customer {
                display: none;
            } */
            .customer-section,
            .create-account,
            .continue-as-guest,
            .shipping-section,
            .ghost {
                display: none;
            }

            .payment-form,
            .shipping-summary {
                display: block;
            }

            .request-invoice-block {

                .request-invoice-fieldset {
                    padding-top: 20px;
                }

                .request-invoice-fieldset,
                .request-invoice-fields {
                    display: none;
                }
            }

            .next-step-button{
                .privacy-info {
                    display: inline-block;
                    width: 100%;
                }
            }

            .next-step-button{
                button.submit-payment {
                    display: inline-block;
                }
            }

            [data-address-mode=customer] {
                .billing-address {
                    display: none;
                }
            }

            [data-address-mode=shipment] {
                .billing-address {
                    display: none;
                }
            }

            [data-address-mode=edit] {
                .billing-address {
                    display: none;
                }
            }

            [data-address-mode=new] {
                .btn-show-details,
                .btn-add-new {
                    display: none;
                }
            }

            [data-address-mode=details] {
                .btn-show-details,
                .btn-add-new {
                    display: none;
                }
            }
        }

        // Place Order -----------------------------
        &[data-checkout-stage=placeOrder] {
            .next-step-button button.submit-customer,
            .customer-section,
            .create-account,
            .continue-as-guest,
            .shipping-section,
            .payment-form,
            .ghost {
                display: none;
            }

            .payment-summary,
            .shipping-summary{
                display: block;
            }

            .next-step-button{
                button.place-order,
                .privacy-info {
                    display: inline-block;
                    width: 100%;
                }
            }

            .gift-cards-list {

                .remove-gift-card {
                    display: none;
                }
            }
        }

        &[data-checkout-stage=submitted] {
            .shipping-form,
            .payment-form,
            .next-step-button button.submit-customer,
            .next-step-button button.place-order,
            .ghost {
                display: none;
            }

            .summary-section-label.shipping-addr-label {
                display: none;
            }

            .payment-summary,
            .shipping-summary {
                display: block;
            }
        }

        option[value=new] {
            display: none;
        }
    }

    [data-customer-type=guest] .single-shipping .shipment-selector-block {
        display: none;
    }

    .pickup-in-store {

        .store-details {
            font-size: $font-size-14px;
        }

        .store-name {
            font-size: $font-size-14px;
        }
    }

    .single-shipping{
        .multi-ship-action-buttons,
        .view-address-block {
            display: none;
        }
    }

    .multi-shipping {
        display: none;
    }


    /* CHECKOUT CONFIRMATION */
    .create-account-container{
        @include media-breakpoint-up(md){
            padding-right: 50px;
            @include flexbox-right-top();

            &.guest{
                .order-confirm-create-account{
                    max-width: 375px;
                }
            }
        }

        .order-confirm-create-account{
            .create-account-actions{
                margin-top: 15px;

                button{
                    width: 100%;
                }
            }
        }
    }

    .confirmation-details-container{
        @include media-breakpoint-up(md){
            &.guest{
                max-width: 665px;
            }

            &.registred{
                max-width: 665px;
                margin-left: auto;
                margin-right: auto;
            }
            /* & > *{
                max-width: 665px;
            } */
        }

        .summary-details{
            &.order-data-container{
                .order-data-wrapper{
                    span:not(.label){
                        font-weight: 700;
                        margin-left: 7px;
                    }

                    & + .order-data-wrapper{
                        margin-top: 7px;
                    }
                }
            }
        }

        .single-shipping{
            margin: 25px 0 0;
        }

        .continue-shopping-container{
            margin-top: 40px;
            margin-bottom: 40px;

            a{
                width: 100%;
                text-align: center;
            }
        }
    }
}

/* CHECKOUT CONFIRMATION HERO */
.checkout-hero-confirmation{
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 15px;
    background-color: $black;
    color: $white;
    font-size: $font-size-25px;
    text-transform: uppercase;
    text-align: center;
    
    @include media-breakpoint-down(md){
        padding-left: 12px;
        padding-right: 12px;
    }

    @include media-breakpoint-up(md){
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .order-thank-you-msg{
        color: inherit;
    }

    .order-thank-you-email-msg{
        font-weight: 400;
        margin: 0;
    }
}
