// CAROUSEL
.wg42,
.wg43{
    .wg-text-box {
        h2, h3, h4{
            text-transform: none;
            font-size: $font-size-25px;
        }
    }

    &.wg.row {
        margin-left: -11px;
        margin-right: -11px;
    }
}

.wg42{
    &.social-carousel-container{
        background-color: $very-light-gray;
        padding-top: 25px;
        padding-bottom: 50px;
    }

    [data-link] {
        cursor: pointer;
    }

    [data-link=""] {
        cursor: inherit;
    }
}

.wg43.video-carousel{
    .video-cover{
        position: relative;

        .play-video{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: inline-block;
            width: 68px;
            height: 68px;
            background: transparent url('../images/svg/play-white.svg') no-repeat center center;
            z-index: 1;

            &.black{
                background: transparent url('../images/svg/play.svg') no-repeat center center;
            }
        }
    }
}
