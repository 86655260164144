/* FLEXBOX */
@mixin flexbox(
    $justify-content: flex-start,
    $align-items: stretch,
    $flex-direction: row,
    $flex-wrap: nowrap,
    $align-content: stretch
){
    display: flex;
    flex-direction: $flex-direction;  // default: row
    flex-wrap: $flex-wrap; //default: nowrap
    justify-content: $justify-content; // default: flex-start
    align-items: $align-items; // default: stretch
    align-content: $align-content; //default: stretch; apply diffirent value if there is more than one row of items
}


// alignment along the x y axis @mixin flexbox-x-y

@mixin flexbox-center-center($flex-direction: row){
    @include flexbox(center, center, $flex-direction)
}


@mixin flexbox-left-center($flex-direction: row){
    @if $flex-direction == row {
        @include flexbox(flex-start, center, $flex-direction)
    }
    @if $flex-direction == column {
        @include flexbox(center, flex-start, $flex-direction)
    }
}


@mixin flexbox-right-center($flex-direction: row){
    @if $flex-direction == row {
        @include flexbox(flex-end, center, $flex-direction)
    }
    @if $flex-direction == column {
        @include flexbox(center, flex-end, $flex-direction)
    }
}


@mixin flexbox-top-center($flex-direction: row){
    @if $flex-direction == row {
        @include flexbox(center, flex-start, $flex-direction)
    }
    @if $flex-direction == column {
        @include flexbox(flex-start, center, $flex-direction)
    }
}


@mixin flexbox-bottom-center($flex-direction: row){
    @if $flex-direction == row {
        @include flexbox(center, flex-end, $flex-direction)
    }
    @if $flex-direction == column {
        @include flexbox(flex-end, center, $flex-direction)
    }
}


@mixin flexbox-left-top($flex-direction: row){
    @include flexbox(flex-start, flex-start, $flex-direction)
}


@mixin flexbox-right-top($flex-direction: row){
    @if $flex-direction == row {
        @include flexbox(flex-end, flex-start, $flex-direction)
    }
    @if $flex-direction == column {
        @include flexbox(flex-start, flex-end, $flex-direction)
    }
}


@mixin flexbox-left-bottom($flex-direction: row){
    @if $flex-direction == row {
        @include flexbox(flex-start, flex-end, $flex-direction)
    }
    @if $flex-direction == column {
        @include flexbox(flex-end flex-start, $flex-direction)
    }
}


@mixin flexbox-right-bottom($flex-direction: row){
    @include flexbox(flex-end, flex-end, $flex-direction)
}
