.page-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    //background: $white;
    z-index: $zindex-header-fixed;

    .header-banner-container,
    .header-content{
        width: 100%;
        @include media-breakpoint-up(xxl){
            max-width: $page-max-width;
        }
    }

    .header-content{
        background-color: $white;
        position: relative;

        & > div:not(.bottom-header-row){
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }
    }

    &.headernomenu{
        .logo-container{
            @include flexbox-center-center();
            height: $header-nomenu-height_mobile;

            @include media-breakpoint-up(lg) {
                height: $header-nomenu-height;
            }
        }
    }
}

.top-header-row {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;

    @include media-breakpoint-down(lg) { //breakpoint(medium down)
        padding: 0;
        height: $header-height_mobile;
    }

    a, button {
        &.with-icon {
            white-space: nowrap;
            text-decoration: none;

            @include media-breakpoint-down(lg) {
                span{
                    display: none;
                }
            }
        }
    }

    .middle-header-row{
        @include flexbox-center-center();

        @include media-breakpoint-down(sm) {
            @include flexbox-center-center();
        }
    }

    .left-container,
    .right-container {
        font-size: 0.813rem;

        @include media-breakpoint-up(lg) {
            & > a:not(.hide-for-large) + a:not(.hide-for-large),
            & > a:not(.hide-for-large) + div:not(.hide-for-large),
            & > a:not(.hide-for-large) + button:not(.hide-for-large),
            & > button:not(.hide-for-large) + button:not(.hide-for-large),
            & > button:not(.hide-for-large) + a:not(.hide-for-large),
            & > a:not(.hide-for-large) + div:not(.hide-for-large),
            & > div:not(.hide-for-large) + a:not(.hide-for-large) {
                margin-left: 7%;
            }
        }

        @include media-breakpoint-down(lg) {
            & > a + a,
            & > a + div,
            & > a + button,
            & > button + button,
            & > button + a,
            & > a + div,
            & > div + a {
                margin-left: 15%;
            }
        }

        @include media-breakpoint-down(sm) {
            & > a + a,
            & > a + div,
            & > a + button,
            & > button + button,
            & > button + a,
            & > a + div,
            & > div + a {
                margin-left: 20px;
            }
        }
    }

    .left-container {
        @include flexbox-left-center();
        @include media-breakpoint-down(sm) {
            width: auto !important;
            flex-basis: auto !important;
        }

        #search-btn-ada {
            @include media-breakpoint-down(lg) { //breakpoint(medium down)
                padding: 0;
            }
        }
    }

    .right-container {
        @include flexbox-right-center();

        .my-account.with-icon {
            > .account {
                height: 16px;
                width: 16px;
            }
        }

        .user {
            position: relative;

            .my-account:hover {
                text-decoration: none;
            }

            @include popover();
        }

        .heart,
        .heart-full {
            width: 16px;
            height: 16px;
        }

        .heart-full {
            display: none;
        }

        .wishlist-added {
            .heart {
                display: none;
            }

            .heart-full {
                display: inherit;
            }
        }
    }
}

.logo-container {
    text-align: center;
    margin: 0;

    h1 {
        line-height: 1;
    }

    svg {
        width: 98px;
        height: 25px;

        @include media-breakpoint-up(lg) {
            width: 157px;
            height: 40px;
        }
    }

    img {
        width: 101px;

        @include media-breakpoint-up(lg) {
            width: 160px;
        }
    }
}

.bottom-header-row {
    width: 100%;
    @include media-breakpoint-down(lg) { //breakpoint(medium down)
        position: relative;
        z-index: 1;
    }
}

a.skip-main {
    top: -100px;
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
}

a.skip-main:focus,
a.skip-main:active {
    position: absolute;
    color: $white;
    background-color: $black;
    left: 35px;
    margin-top: 10px;
    width: 15%;
    height: auto;
    overflow: auto;
    padding: 5px;
    text-align: center;
    font-size: 1.2em;
    z-index: 999;
}

.header-banner-container{
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.813rem;
    color: $white;
    background-color: $black;
    @include set-page-padding-x();

    p{
        margin-bottom: 0;
    }

    div[class^="col"]{
        @include flexbox-right-center();
        position: relative;
    }

    .promo-banner-content{
        width: 100%;
        margin: 0 auto;
        text-align: center;
        @include media-breakpoint-up(lg) {
            width: calc(100% - #{$locale-container-width} * 2);
        }
        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }

        .promo-banner-content_wrapper{
            @include flexbox-center-center();
            width: auto;
            text-align: inherit;

            svg{
                display: inline-block;
                width: 14px;
                height: 14px;

                &:first-child{
                    margin-right: 7%;
                }

                &:last-child{
                    margin-left: 7%;
                }
            }
        }
        p{
            display: inline;
            padding: 0 5px;
        }
    }
}


body{
    @include media-breakpoint-down(lg){
        .header-content{
            .open-mobile-nav{
                svg.close-icon{
                    display: none;
                }
            }
        }
    }

    &.nav-open{
        @include media-breakpoint-down(lg){
            .header-content{
                .open-mobile-nav{
                    svg:not(.close-icon){
                        display: none;
                    }

                    svg.close-icon{
                        display: inline;
                    }
                }
            }
        }
    }
}
