// OVERRIDE BOOTSRAP ACCORDION STYLE

.accordion{
    .accordion-item{
        border-radius: 0;
        border: 0 none;
        border-bottom: 1px solid $black;

        .accordion-button{
            font-weight: 600;
            letter-spacing: 0.4pt;
            text-transform: uppercase;
            color: $black;
            box-shadow: none !important;
            min-height: 50px;
            padding: 10px 0;
            @include flexbox-left-center();

            &::after{
                content: "+";
                background-image: none !important;
                font-size: 1.5rem; // 24px
                line-height: 1.5rem;
                font-weight: 300;
                transform: none !important;
                width: 24px;
                height: 24px;
                text-align: center;
            }

            &[aria-expanded="true"]{
                &::after{
                    content: "-";
                }
            }

            &:not(.collapsed){
                background-color: $white;
            }

            &:focus{
                box-shadow: none;
                border: 0 none;
            }
        }

        .accordion-body{
            padding: 0 0 20px;
        }
    }
}
