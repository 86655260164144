.login-page{
    .medium-content{
        & > [class^="col"]{
            max-width: 1030px;
        }
    }

    .login-wrapper,
    .not-registered-wrapper{
        margin-bottom: 50px;
    }

    .login-wrapper{
        .remember-me{
            label{
                font-size: $font-size-14px;
                line-height: 24px;
            }
        }

        .forgot-password{
            text-align: right;
        }

        form.login-oauth{
            margin-top: 40px;
        }
    }

    .not-registered-wrapper{
        .not-registered-banner{
            padding: 30px 40px 40px;
            background-color: $very-light-gray;

            h2, .h2{
                font-size: $font-size-25px;
                text-transform: uppercase;

                & + p{
                    margin-top: 25px;
                    margin-bottom: 0;
                }
            }

            p + p{
                margin-top: 15px;
            }

            .button-primary{
                margin-top: 25px;
                width: 100%;
                text-align: center;
            }
        }
    }
}
