/* BUTTON */
@mixin primary-button(
    $color: $btn-primary-color,
    $font-size: $font-size-14px, //14px
    $line-height: $line-height-base,
    $letter-spacing: 0.4pt,
    $background-color: $btn-primary-bg,
    $border-color: $btn-primary-border,
    $padding: 15px 25px,
    $color-hover: $btn-primary-color-hover,
    $background-color-hover: $btn-primary-bg-hover
){
    font-family: $font-family-primary;
    font-size: $font-size;
    line-height: $line-height;
    letter-spacing: $letter-spacing;
    color: $color;
    background-color: $background-color;
    border: 1px solid $border-color;
    padding: $padding;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;

    span{
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        font-weight: inherit;
    }

    &:hover,
    &:active,
    &:focus{
        color: $color-hover;
        background-color: $background-color-hover;
        text-decoration: none;
    }
}

@mixin secondary-button(
    $font-size: $font-size-14px, //14px
    $line-height: $line-height-base,
    $letter-spacing: 0.4pt,
    $color: $btn-secondary-color,
    $background-color: $btn-secondary-bg,
    $border-color: $btn-secondary-border,
    $padding: 15px 25px,
    $color-hover: $btn-secondary-color-hover,
    $background-color-hover: $btn-secondary-bg-hover
){
    font-family: $font-family-secondary;
    font-size: $font-size;
    line-height: $line-height;
    letter-spacing: $letter-spacing;
    color: $color;
    background-color: $background-color;
    border: 1px solid $border-color;
    padding: $padding;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;

    span{
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        font-weight: inherit;
    }

    &:hover,
    &:active,
    &:focus{
        color: $color-hover;
        background-color: $background-color-hover;
        text-decoration: none;
    }
}

@mixin button-as-link($hoverState: true){
    border: 0 none;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;
    color: $link-color;
    font-size: $font-size-14px;
    font-weight: 700;
    line-height: inherit;
    letter-spacing: 0.4pt;
    padding: 0;
    display: inline-block;

    &:hover,
    &:focus,
    &:active,
    &:disabled{
        border: 0 none;
        border-radius: 0;
        background-color: transparent;
        color: $link-hover-color;
        padding: 0;
    }

    @if $hoverState == true{
        span:not(.icon, .tooltip){
            border-bottom: 1px solid transparent;
        }

        &:hover,
        &:focus{
            span:not(.icon, .tooltip){
                border-bottom: 1px solid $black;
            }
        }
    }

    &.underlined{
        &:hover{
            span{
                border-bottom: 1px solid transparent;
            }
        }
    }
}

@mixin button-as-link-italic(){
    @include button-as-link();

    font-family: $font-family-base;
    font-size: 15px;
    letter-spacing: 0.6pt;
    font-style: italic;
}

@mixin icon-button(
    $width: 16px,
    $height: 16px
) {
    @include button-as-link($hoverState: false);

    svg{
        width: $width;
        height: $height;
    }

    span:not(.icon, .tooltip){
        width: 1px;
        height: 1px;
        border: 0;
        clip: rect(0, 0, 0, 0);
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
    }
}
