$mapHeight: 425px;


.checkoutpage{
    /* Shipping Step */
    .step-shipping{
        .shipping-form{
            &.click-collect-selected{
                & > *:not(.shipping-address){
                    display: none;
                }

                .shipping-address{
                    & > *:not(.gift-message-block){
                        display: none;
                    }
                }
            }
        }

        .click-and-collect-container{
            margin-top: 25px;
        }

        .delivery-options-container{
            & > .radio-field{
                margin-bottom: 20px;

                &:first-child{
                    margin-right: 20px;
                }
            }

            & + .shipping-form{
                margin-top: 5px;
            }
        }

        #search-stores-container{
            display: none;
            margin-top: 15px;
            margin-bottom: 15px;

            &.show {
                display: flex;
            }

            @media (max-width: 640px) {
                .col-store-list{
                    order: 2;
                    padding-top: 30px;
                }

                .col-map-canvas{
                    order: 1;
                }
            }

            form.row{
                align-items: flex-end;

                button:not(.reset-button){
                    width: 100%;
                }

                .clickcollect-search-input{
                    //padding-right: 20px;

                    .form-group{
                        position: relative;

                        .reset-button{
                            position: absolute;
                            top: 17px;
                            right: 0;
                            z-index: 1;
                            width: 40px;
                            height: 50px;
                        }

                        input#clickcollect-search-autocomplete{
                            padding-right: 55px;
                        }
                    }
                }
            }

            .store-list-wrapper{
                padding-right: 3px;

                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    min-height: 80px;
                    max-height: $mapHeight;
                    overflow-y: auto;

                    li{
                        @include flexbox-left-top();
                        flex-wrap: nowrap;
                        padding-right: 20px;

                        & + li{
                            margin-top: 20px;
                        }

                        .radio-container{
                            padding-right: 10px;
                            padding-top: 3px;
                        }

                        p{
                            margin-bottom: 0;
                            font-size: 14px;

                            &.name{
                                font-weight: 400;
                            }

                            & + p{
                                margin-top: 3px;
                            }
                        }
                    }
                }
            }
        }

        .map-canvas {
            height: $mapHeight;

            .map-store-details{
                .map-store-name{
                    font-size: 14px;
                    margin-bottom: 7px;
                }

                address{
                    margin-bottom: 0;
                    line-height: 1.6;

                    .map-store-phone{
                        @include flexbox-left-center();
                        padding-top: 8px;
                        line-height: 1;

                        .icon-phone{
                            width: 16px;
                            height: 16px;
                            margin-right: 5px;
                            background: $white url('../images/svg/phone.svg') no-repeat center center;
                        }
                    }
                }
            }
        }
    }
}
