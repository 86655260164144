.order-list-container{
    .order-item{
        &.order-to-return-item{
            .product-summary-block{
                padding-left: 0;

                .product-line-item{
                    border-bottom: 1px solid #ccc;

                    & + .product-line-item:before{
                        content: none;
                    }
                }

                .checkbox-field{
                    margin-right: 15px;
                }

                .form-group{
                    &.return-count-form,
                    &.return-reason-form{
                        margin-top: 10px;
                        margin-bottom: 25px;
                    }
                }
            }
        }

        .product-summary-block{
            margin-top: 15px;

            .product-line-item{
                border-bottom: 0 none;
            }

            .line-item-name,
            .line-item-total-price{
                font-size: 1rem;
            }
        }

        .item-details{
            //@include media-breakpoint-up(md){
                @include flexbox-left-top();
                flex-wrap: wrap;
            //}

            &.order-info{
                padding-left: 0;

                &.return-wrapper{
                    background-color: $very-light-gray;
                    padding: 15px;

                    & + .return-wrapper{
                        margin-top: 20px;
                    }
                }

                .order-status,
                .return-status{
                    font-size: 1.125rem;
                    font-weight: 700;

                    p, span{
                        font-size: inherit;
                        font-weight: inherit;
                        letter-spacing: 0.2pt;
                    }
                }

                .return-status{
                    padding-left: 10px;

                    & + .product-summary-block{
                        margin-top: 0;
                        padding-left: 10px;
                    }
                }
            }

            & > *{
                width: 100%;
            }

            .line-item-total-price{
                width: 100%;
                max-width: 100%;
                flex: 1 0 0%;
                font-weight: 700;
                letter-spacing: 0.4pt;
                padding-right: 30px;
            }

            .line-item-order-number{
                width: 100%;
                max-width: 100%;
                flex: 1 0 0%;
                padding-right: 30px;
            }

            .product-line-toolbar{
                text-align: right;
                display: block;

                a{
                    padding-bottom: 5px;
                }

                a + a{
                    margin-left: 25px;
                }
            }
        }
    }

    &.return-form{
        .checkbox-field input[type=checkbox]{
            flex: none;
        }

        .alert-danger{
            color: $alert;
            margin: 30px 0;
        }

        .return-flow{
            text-align: left;
            padding-bottom: 15px;
            border-bottom: 1px solid #ccc;

            & > div {

                .value{
                    font-weight: 500;
                }

                & + div{
                    margin-top: 10px;
                }
            }

        }

        .delivery-store-data{
            padding-top: 15px;
            padding-bottom: 8px;
            border-bottom: 1px solid #ccc;

            h5{
                margin-bottom: 13px;
            }

            p{
                margin-bottom: 7px;
            }
        }

        .return-actions-container{
            text-align: center;
            margin-top: 30px;

            .confirmReturnPopup{
                width: 100%;
            }
        }

        .modal{
            .modal-header.title-container{
                padding-top: 0;
                padding-bottom: 10px;
            }

            .product-summary-block{
                text-align: left;

                .product-line-item{
                    border-bottom: 0 none;
                }

                .line-item-quantity,
                .line-item-total-price{
                    display: none;
                }
            }

            .modal-footer{
                @include flexbox-center-center();
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 0;
                margin: 20px -10px 25px;

                .button-wrapper{
                    padding: 15px 0;
                    margin: 0 10px;
                }
            }
        }
    }


}

.order-history-page,
.account-page{
    .order-list-container,
    .card-header{
        a.button{
            text-transform: uppercase;
            font-size: $font-size-12px;
            font-weight: 700;
            letter-spacing: 0.4pt;
        }
    }
}

.account-page{
    .order-list-container{
        .order-item{
            font-size: $font-size-14px;

            .line-item-name{
                margin-bottom: 0;
            }

            .line-item-quantity{
                font-weight: 500;
            }

            .line-item-total-price{
                span + span{
                    margin-left: 4px;
                }
            }
        }
    }
}

.order-history-page{

    .order-history-form-group {
        width: 100%;
    }

    .order-list-container{
        .order-item{
            font-weight: 300;
            font-size: 1rem;
            padding-top: 30px;
            padding-bottom: 30px;

            &:first-child{
                padding-top: 0;
            }

            &:last-child{
                border-bottom: 1px solid $medium-gray;
            }
        }

        .item-image{
            img{
                max-width: 110px;

                @include media-breakpoint-up(sm){
                    max-width: 145px;
                }
            }
        }

        .line-item-order-number,
        .line-item-order-date{
            @include media-breakpoint-down(sm){
                font-size: $font-size-14px;
            }
        }

        .line-item-order-date{
            margin-bottom: 15px;
        }

        .item-attributes,
        .line-item-quantity{
            font-size: $font-size-14px;
        }

        .line-item-quantity{
            font-weight: 500;
        }

        .line-item-total-price{
            font-size: $font-size-14px;

            @include media-breakpoint-up(sm){
                font-size: $font-size-18px;
            }

            .label{
                margin-right: 7px;
            }
        }
    }
}
