.social-container{
    @include flexbox-left-center();
    position: relative;

    .social-container-label{
        font-size: $font-size-14px;
        font-weight: 400;
        padding-right: 10px;
    }

    ul.social-list{
        @include resetUl();
        @include flexbox-left-center();

        li{
            padding: 5px 3px;

            .share-on-whatsapp{
                margin-left: 3px;
            }

            & + li{
                margin-left: 10px;
            }
        }
    }

    .copy-link-message{
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        display: inline-block;
        padding: 10px 15px;
        background-color: #fff;
        border: 1px solid $light-gray;
        font-size: $font-size-14px;

        &.hide{
            display: none;
        }
    }
}
