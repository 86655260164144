.minicart{
    .minicart-link{
        position: relative;

        span{
            font-weight: 400;
        }

        span.minicart-quantity{
            position: absolute;
            top: -7px;
            right: -10px;
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            background-color: $very-light-gray;
            letter-spacing: 0;
            font-size: 12px;
            line-height: 20px;

            @include media-breakpoint-up(lg){
                margin-left: 3px;
                position: unset;
                width: auto;
                height: auto;
                border-radius: 0;
                background-color: transparent;

                &:before{
                    content: "(";
                    margin-right: 1px;
                }

                &:after{
                    content: ")";
                    margin-left: 1px;
                }
            }
        }
    }

    .minicart-side{
        position: fixed;
        right: 0;
        top: 0;
        transform: translateX(100%);
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background-color: rgba(0,0,0,0);
        z-index: $zindex-minicart;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background-color 0.2s cubic-bezier(0.77,0.2,0.05,1.0);

        .minicart-content{
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            width: 100%;
            max-width: 390px;
            height: 100vh;
            background-color: $white;

            .container {
                padding: 0 15px;
            }

            .minicart-header{
                position: relative;

                & > .col-12{
                    padding-top: 15px;
                    padding-bottom: 5px;

                    .minicart-title{
                        font-size: $font-size-25px;

                        span{
                            font-size: 1rem;
                            font-weight: 300;
                            margin-left: 3px;

                            &:before{
                                content: "(";
                                margin-right: 1px;
                            }

                            &:after{
                                content: ")";
                                margin-left: 1px;
                            }
                        }
                    }

                    .close-minicart{
                        @include button-as-link($hoverState: false);
                        width: 20px;
                        height: 20px;
                        background: transparent url('../images/svg/close.svg') no-repeat center center;
                        background-size: 20px 20px;
                        margin-left: auto;
                        position: absolute;
                        right: 15px;
                        top: 23px;
                    }
                }
            }

            .minicart-body{
                overflow-y: auto;
                overflow-x: hidden;

                .bar {
                    display: inline-flex;
                    align-items: center;
                    gap: 5px;

                    > * {
                        flex-shrink: 0;
                    }

                    span {
                        color: #b0a9a9;
                    }

                    progress {
                        flex-basis: 100%;
                        -webkit-appearance: none;
                        height: 8px;
                        border-radius: 7px;
                    }

                    progress::-webkit-progress-bar {
                        background-color: #e8e2e2;
                        border-radius: 7px;
                    }

                    progress::-webkit-progress-value {
                        background-color: #ab0202;
                        border-radius: 7px;
                    }

                    progress::-moz-progress-bar {
                        background-color: #ab0202;
                    }
                }

                .minicart-content-slot { margin-bottom: 10px; }
            }

            .minicart-footer{
                padding: 15px 25px 15px 15px;
                margin: auto -15px 0;
                box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.3);
                position: fixed;
                bottom: 0;

                .minicart-coupons-section {
                    .coupon-code-container{
                        margin-bottom: 15px;

                        .coupon-error{
                            color: $alert;
                            font-size: $font-size-14px;

                            .coupon-missing-error{
                                display: none;
                            }
                        }

                        .promo-code-input{
                            padding-right: 7px;
                        }

                        label{
                            font-size: 0.625rem;
                            font-weight: 400;
                            text-transform: uppercase;
                            color: #7F7F7F;
                        }

                        input[type="text"]{
                            width: 100%;
                            height: 100%;
                            line-height: 1;
                        }

                        button[type="submit"]{
                            width: 100%;
                            padding: 15px;
                        }
                    }
                }

                .minicart-actions{
                    margin-top: auto;
                    text-align: center;

                    .checkout-btn{
                        margin-bottom: 20px;
                    }

                    .view-cart{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    &.show{
        .minicart-side{
            right: 0;
            transform: translateX(0);
            background-color: rgba(0,0,0,0.5);
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background-color 1.3s cubic-bezier(0.77,0.2,0.05,1.0);
        }
    }

    .cart{
        .card{
            position: relative;

            .line-item-name,
            .line-item-unit-price,
            .line-item-total-price,
            .item-attributes,
            .line-item-quantity,
            .line-item-promo{
                margin-bottom: 7px;
            }

            .item-attributes{
                .line-item-attributes{
                    font-size: $font-size-12px;
                    margin-top: 0;
                }
            }

            .remove-product-dialog{
                @include flexbox-center-center();
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.95);
                text-align: center;

                &.show{
                    display: flex;
                }

                button{
                    padding: 10px 20px;
                }

                button + button{
                    margin-left: 20px;
                }

                .remove-product-dialog-body{
                    font-weight: 300;

                    .modal-title{
                        font-size: 1.375rem;
                        font-weight: 600;
                        margin-bottom: 7px;
                        line-height: 1.2;
                    }

                    .product-to-remove{
                        font-weight: 400;
                        margin: 10px 0 20px;
                    }
                }
            }
        }
    }

    .minicart-empty{
        font-size: $font-size-18px;
        margin: 30px 15px;
    }

    .ecs-button{
        margin: 15px 0px;
    }
}
