.order-details-page,
.return-success-page{
    .multi-shipping{
        display: none;
    }

    .confirm-details{
        @include flexbox-left-top();
        @include media-breakpoint-down(lg){
            flex-wrap: wrap;
        }
        @include media-breakpoint-up(lg){
            flex-wrap: nowrap;
            justify-content: space-between;
        }
        border-bottom: 1px solid $light-gray;
        padding-bottom: 5px;
        margin-bottom: 25px;

        .col-left,
        .col-right,
        .col-request-return_button-wrapper{
            flex: 0 0 auto;
            width: 100%;

            @include media-breakpoint-up(lg){
                width: auto;
            }
        }

        .col-left,
        .col-right{
            //width: 50%;
            font-weight: 300;

            .checkout-step-header{
                display: none;
            }

            .summary-section-label{
                font-size: $font-size-18px;
                margin-bottom: 15px;
            }

            .summary-details{
                margin-bottom: 25px;
            }
        }

        .col-left{
            @include media-breakpoint-up(lg){
                padding-right: 25px;
            }
        }

        .col-right{
            @include media-breakpoint-up(lg){
                padding-left: 25px;
            }
        }

        .col-request-return_button-wrapper{
            .button-primary{
                width: 100%;
            }

            @include media-breakpoint-up(md){
                margin-left: auto;
                padding-left: 25px;

                .button-primary{
                    width: auto;
                }
            }
        }
    }

    .order-data-container{
        .order-data-wrapper:first-child{
            font-size: $font-size-18px;
            font-weight: 700;
        }

        .cancel-return-order{
            margin-top: 10px;
        }
    }

    .order-product-container{
        .product-summary-header{
            @include flexbox-center-center();
            justify-content: space-between;
            font-size: $font-size-18px;
            font-weight: 700;
        }

        .product-summary-block{
            .item-details{
                font-size: $font-size-14px;
            }

            .line-item-name,
            .line-item-total-price{
                font-size: 1rem;
            }
        }

    }

    .order-total-container{
        background-color: $very-light-gray;
        padding: 25px 20px;

        .grand-total-container{
            font-size: $font-size-18px;
            font-weight: 700;

            p.label{
                font-size: inherit;
                font-weight: inherit;
            }
        }
    }

    .my-account{
        text-align: center;
        padding: 20px;
        margin-top: 40px;
    }
}

.return-page{
    .delivery-options{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        //margin-bottom: 25px;

        .radio-field:first-child{
            margin-right: 30px;
        }
    }

    .order-list-container {
        .order-item {
            .item-details {
                display: block;
            }
        }
    }

    .order-cancel-select-all-form{
        .checkbox-field{
            margin-top: 25px;
            margin-bottom: 0;
        }
    }

    .order-to-return-item{
        padding-top: 0;
    }

    #home-delivery-return-container {
        font-size: $font-size-14px;

        a {
            text-decoration: underline;
        }
    }

    #search-stores-container,
    #home-delivery-return-container {
        display: none;
        margin-top: 15px;
        margin-bottom: 15px;

        &.show {
            display: flex;
        }

        @media (max-width: 640px) {
            .col-store-list{
                order: 2;
                padding-top: 30px;
            }

            .col-map-canvas{
                order: 1;
            }
        }

        form.row{
            align-items: flex-end;

            button:not(.reset-button){
                width: 100%;
            }

            .clickcollect-search-input{
                //padding-right: 20px;

                .form-group{
                    position: relative;

                    .reset-button{
                        position: absolute;
                        top: 17px;
                        right: 0;
                        z-index: 1;
                        width: 40px;
                        height: 50px;
                    }

                    input#clickcollect-search-autocomplete{
                        padding-right: 55px;
                    }
                }
            }
        }

        .store-list-wrapper{
            padding-right: 3px;

            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                min-height: 80px;
                max-height: $mapHeight;
                overflow-y: auto;

                li{
                    @include flexbox-left-top();
                    flex-wrap: nowrap;
                    padding-right: 20px;

                    & + li{
                        margin-top: 20px;
                    }

                    .radio-container{
                        padding-right: 10px;
                        padding-top: 3px;
                    }

                    p{
                        margin-bottom: 0;
                        font-size: 14px;

                        &.name{
                            font-weight: 400;
                        }

                        & + p{
                            margin-top: 3px;
                        }
                    }
                }
            }
        }
    }

    .map-canvas {
        height: $mapHeight;

        .map-store-details{
            .map-store-name{
                font-size: 14px;
                margin-bottom: 7px;
            }

            address{
                margin-bottom: 0;
                line-height: 1.6;

                .map-store-phone{
                    @include flexbox-left-center();
                    padding-top: 8px;
                    line-height: 1;

                    .icon-phone{
                        width: 16px;
                        height: 16px;
                        margin-right: 5px;
                        background: $white url('../images/svg/phone.svg') no-repeat center center;
                    }
                }
            }
        }
    }
}

.return-success-page{
    .return-order-list-container,
    .return-order-totals{
        margin-top: 20px;
    }

    .product-summary-block{
        .line-item-quantity,
        .line-item-total-price{
            display: none;
        }
    }

    .return-order-totals{
        h2{
            margin-bottom: 20px;
        }
    }
}
