.showForAdaTabNav {
    display: none;
}

[tabindex] {
    @include ada-outline;
}

.visually-hidden,
.sr-only {
    width: 1px;
    height: 1px;
    border: 0;
    clip: rect(0, 0, 0, 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}
