// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

@import "colors";

// 2. Include any default variable overrides here
@import "override-bootstrap-vars";

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/tables";

// Page Layout
//------------------------------------
//$page-max-width: 1440px;
$page-max-width: 100%;
$page-padding-x: 20px;
$page-padding-x_mobile: 0;


// Base Typography
//------------------------------------
$font-family-primary: $font-family-base;
$font-family-secondary: "Work Sans", sans-serif;
$font-family-tertiary: $font-family-secondary;
$letter-spacing-base: normal;
$font-size-12px: 0.75rem;
$font-size-14px: 0.875rem;
$font-size-18px: 1.125rem;
$font-size-20px: 1.25rem;
$font-size-25px: 1.563rem;
$font-size-35px: 2.188rem;
$font-size-50px: 3.125rem;


// Z-index
//------------------------------------
$zindex-navbar:            100; // desktop navbar
$zindex-dropdown:          110;
$zindex-header-fixed:      120;
$zindex-navbar-mobile:     130; // mobile navbar
$zindex-search:            140; // desktop
$zindex-minicart:          150; // desktop & mobile
$zindex-popover:           160;
$zindex-modal-background:  170;
$zindex-modal:             180;
$zindex-loader:            190;


//== Buttons
//------------------------------------

$btn-font-weight:                normal;

$btn-default-color:              #fff;
$btn-default-bg:                 $black;
$btn-default-border:             $black;
$btn-default-bg-hover:           $black;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $black;
$btn-primary-border:             $black;
$btn-primary-color-hover:        $black;
$btn-primary-bg-hover:           #fff;

$btn-secondary-color:              $black;
$btn-secondary-bg:                 #fff;
$btn-secondary-border:             $black;
$btn-secondary-color-hover:        #fff;
$btn-secondary-bg-hover:           $black;

$btn-link-disabled-color:        $disabled-color;


// Header
//------------------------------------
$header-height_mobile: 50px;
$navbar-width-mobile: 320px;
$search-container-height_mobile: 50px;
// Checkout header
$header-nomenu-height: 100px;
$header-nomenu-height_mobile: 50px;


// Padding Tile / Card
//------------------------------------
$padding-tile-x: 15px;
$padding-tile-x_mobile: 5px;

// Locale desktop
//------------------------------------
$locale-container-width: 170px;

// Margin
$marginb-10: 10px;
$margin-50-0: 50px 0;