// HERO SLIDER
.wg41 { // it's new
    .slide-image-container{
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 516px;

        @include media-breakpoint-up(lg) {
            height: 516px;
        }

        @include media-breakpoint-up(xl) {
            height: 690px;
        }
    }

    .container-img {
        &[data-link] {
            cursor: pointer;
        }

        &[data-link=""] {
            cursor: inherit;
        }
    }

    .wg-text-box {
        padding-left: $page-padding-x_mobile;
        padding-right: $page-padding-x_mobile;

        h2, .h2,
        h3, .h3,
        h4, .h4{
            font-size: $font-size-35px;
        }

        @include media-breakpoint-up(sm) {
            padding-left: $page-padding-x;
            padding-right: $page-padding-x;

            h2, .h2,
            h3, .h3,
            h4, .h4{
                font-size: $font-size-50px;
            }
        }
    }

    .slider{
        display: flex;
        overflow: hidden;

        > div {
            flex: 0 0 100%;
        }

        .slick-slide{
            position: relative;
        }

        .slick-dots {
            top: 100%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -100%);

            li{
                button {
                    background-color: $white;
                }

                &.slick-active{
                    button {
                        background-color: $black;
                    }
                }
            }
        }
    }
}
