@include wghelpers();

.wg {
    @at-root a#{&} {
        &:not(.img-container) {
            display: block;
            text-decoration: none;
        }
    }
    position: relative;

    img{
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .wg-text-box {
        margin-top: 20px;

        &:empty {
            display: none;
        }

        h1, .h1,
        h2, .h2,
        h3, .h3,
        h4, .h4,
        h5, .h5,
        h6, .h6{
            text-transform: uppercase;
/*             font-weight: 700;
            letter-spacing: 0.4pt; */


            span{
                display: block;
                font-weight: 300;
            }
        }

        h1, .h1{
            line-height: 1.2;
            font-size: $font-size-35px;

            @include media-breakpoint-up(md) {
                font-size: $font-size-50px;
            }

            &.small{
                font-size: $font-size-35px;

                @include media-breakpoint-up(md) {
                    font-size: $font-size-35px;
                }
            }

            & + h2,
            & + .h2{
                margin-top: 40px;
            }

            & + h3,
            & + .h3{
                margin-top: 35px;
            }

            & + form{
                margin-top: 30px;
            }
        }

        h2, .h2{
            line-height: 1.2;
            font-size: $font-size-25px;

            @include media-breakpoint-up(md) {
                font-size: $font-size-35px;
            }

            &.small{
                font-size: $font-size-25px;

                @include media-breakpoint-up(md) {
                    font-size: $font-size-25px;
                }
            }

            &.big{
                font-size: $font-size-35px;

                @include media-breakpoint-up(md) {
                    font-size: $font-size-50px;
                }
            }

            & + span{
                margin-top: 7px;
            }

            & + h3,
            & + .h3,
            & + h4,
            & + .h4{
                margin-top: 30px;
            }

            & + form{
                margin-top: 25px;
            }
        }

        h3, .h3{
            line-height: 1.2;
            font-size: $font-size-25px;

            @include media-breakpoint-up(md) {
                font-size: $font-size-25px;
            }

            &.small{
                font-size: $font-size-18px;

                @include media-breakpoint-up(md) {
                    font-size: $font-size-18px;
                }
            }

            &.big{
                font-size: $font-size-35px;

                @include media-breakpoint-up(md) {
                    font-size: $font-size-35px;
                }
            }

            & + span{
                margin-top: 5px;
            }

            & + h4,
            & + .h4,
            & + h5,
            & + .h5,
            & + form{
                margin-top: 20px;
            }
        }

        h4, .h4{
            line-height: 1.3;
            font-size: $font-size-18px;

            @include media-breakpoint-up(sm) {
                font-size: $font-size-18px;
            }

            &.small{
                font-size: $font-size-14px;

                @include media-breakpoint-up(md) {
                    font-size: $font-size-14px;
                }
            }

            &.big{
                font-size: $font-size-25px;

                @include media-breakpoint-up(md) {
                    font-size: $font-size-25px;
                }
            }

            & + h2,
            & + .h2{
                margin-top: 20px;
            }

            & + h3,
            & + .h3{
                margin-top: 15px;
            }
        }

        h2, .h2,
        h3, .h3{
            & + span{
                display: block;
                font-weight: 300;
            }
        }

        h1, .h1,
        h2, .h2,
        h3, .h3,
        h4, .h4,
        h5, .h5,
        h6, .h6,
        p,
        & > span{
            width: 100%;
            color: inherit;

            & + .button{
                margin-top: 20px;
            }

            & + p{
                margin-top: 15px;
            }
        }

        p{
            margin-bottom: 0;
            line-height: 1.4;
            font-weight: 300;

            b, strong{
                font-weight: 600;
                letter-spacing: 0.3pt;
            }

            &.text-after-cta{
                b, strong{
                    font-weight: 700;
                }
            }
        }

        ul{
            margin: 25px 0;
            padding-left: 17px;

            li{
                font-weight: 300;

                &::marker{
                    font-size: 10px;
                }

                b, strong{
                    font-weight: 600;
                }

                & + li{
                    margin-top: 8px;
                }
            }
        }

        table{
            margin: 25px 0;
            font-size: $font-size-14px;

            th{
              font-weight: 700;
            }

            td{
                font-weight: 300;
            }
        }

        p,
        ul,
        table{
            & + h2,
            & + .h2{
                margin-top: 40px;
            }

            & + h3,
            & + .h3{
                margin-top: 35px;
            }

            & + h3,
            & + .h3,
            & + h4,
            & + .h4{
                margin-top: 30px;
            }

            & + h5,
            & + .h5,
            & + h6,
            & + .h6{
                margin-top: 20px;
            }
        }

        .button{
            svg{
                width: 20px;
                height: 19px;
                margin-left: 7px;
            }
        }

        p + .button{
            margin-top: 25px;
        }

        .button + p{
            margin-top: 20px;
        }
    }

    .slider{
        .wg-text-box{
            width: 100%;
        }
    }
}

.wggroup-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &.two-different-widgets{
        & >  *{
            width: 100%;
        }

        & >  *:first-child{
            margin-bottom: 40px;
        }
    }

    @include media-breakpoint-up(md) {
        align-items: center;
        justify-content: flex-start;

        &.two-different-widgets{

            & >  *{
                width: 50%;
                margin-bottom: 0;
            }
        }
    }
}
