@mixin wghelpers() {
    .widget-title{
        margin: 20px auto;
        text-align: center;

        h2, .h2{
            font-size: $font-size-25px;
            line-height: 1.2;
            font-weight: 700;
            text-transform: uppercase;

            &.small{
                font-size: $font-size-25px;
                font-weight: 600;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        .button,
        button{
            text-transform: uppercase;
            margin-left: auto;
            margin-right: auto;
        }

        h2 + .button,
        h2 + button,
        .h2 + .button,
        .h2 + button{
            margin-top: 15px;
            margin-bottom: 5px;
        }

        @include media-breakpoint-up(sm) {
            h2, .h2{
                font-size: $font-size-35px;
            }
        }

        & + .col{
            flex: 1 0 100%;
            position: relative;
        }
    }

    .wg {
        &.hero{
            .wg-text-box {
                max-width: 966px;

                h1, .h1{
                    line-height: 1.2;
                    font-size: $font-size-35px;

                    @include media-breakpoint-up(md) {
                        font-size: $font-size-50px;
                    }
                }

                p{
                    font-size: $font-size-18px;
                    font-weight: 400;
                    line-height: 1.2;

                    @include media-breakpoint-up(md) {
                        font-size: $font-size-25px;
                    }
                }

                h1 + p,
                .h1 + p{
                    margin-top: 15px;
                }
            }
        }

        // BACKGROUND
        &.black-background{
            background-color: $black;

            .wg-text-box {
                color: $white;

                .button{
                    color: inherit;
                }
            }
        }

        &.dark-background{
            .wg-text-box {
                color: $white;

                .button:not(.white){
                    color: inherit;
                }
            }
        }

        // TEXT
        &.text-right {

            picture img {
                margin-right: 0;
                margin-left: auto;
                width: auto;
            }

            .wg-text-box {
                text-align: right;

                @include media-breakpoint-down(sm) { // breakpoint(small only)
                    text-align: center;
                }
            }

            .button {
                margin-left: auto;
            }
        }

        &.text-left {

            picture img {
                margin-left: 0;
                margin-right: auto;
                width: auto;
            }

            .wg-text-box {
                text-align: left;
            }

            .button {
                margin-left: 0;
            }
        }

        &.text-center {
            .wg-text-box {
                text-align: center;
            }

            .button {
                margin-left: auto;
                margin-right: auto;
            }
        }

        // ALIGNMENT-X-Y
        &.alignment-center-center{
            position: relative;

            .wg-text-box {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &.alignment-top-center{
            position: relative;

            .wg-text-box {
                position: absolute;
                left: 50%;
                top: 4%;
                transform: translate(-50%, -4%);
            }
        }

        &.alignment-bottom-center{
            position: relative;

            .wg-text-box {
                position: absolute;
                left: 50%;
                top: 90%;
                transform: translate(-50%, -95%);
            }
        }

        // SLIDER
        .slider{
            .wg-text-box {
                &.dark-background{
                    color: $white;
                }

                &.text-right {
                    text-align: right;
                    @include media-breakpoint-down(sm) { // breakpoint(small only)
                        text-align: center;
                    }

                    .button {
                        margin-left: auto;
                    }
                }

                &.text-left {
                    text-align: left;

                    .button {
                        margin-left: 0;
                    }
                }

                &.text-center {
                    text-align: center;

                    .button {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                &.alignment-center-center{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                &.alignment-top-center{
                    position: absolute;
                    left: 50%;
                    top: 4%;
                    transform: translate(-50%, -4%);
                }

                &.alignment-bottom-center{
                    position: absolute;
                    left: 50%;
                    top: 95%;
                    transform: translate(-50%, -95%);
                }
            }
        }
    }
}

@mixin widget_image-as-background_height(){
    min-height: 335px;

    @include media-breakpoint-up(md){
        min-height: 400px;
    }
}

@mixin tall-widget_image-as-background_height(){
    min-height: 450px;

    @include media-breakpoint-up(md){
        min-height: 600px;
    }

    @media screen and (min-width: 1160px){
        min-height: 640px;
    }

    @media screen and (min-width: 1280px){
        min-height: 740px;
    }

    @media screen and (min-width: 1400px){
        min-height: 840px;
    }
}
