/*
    wg20: image as background widget, text in overlay
*/
.wg20{
    > .col {
        padding-left: 0;
        padding-right: 0;
    }

    .wg-text-box {
        width: 100%;
        max-width: 700px;

        padding-left: $page-padding-x_mobile;
        padding-right: $page-padding-x_mobile;
        margin-top: 0;

        @include media-breakpoint-up(sm) {
            padding-left: $page-padding-x;
            padding-right: $page-padding-x;
        }
    }

    [data-link] {
        cursor: pointer;
    }

    [data-link=""] {
        cursor: inherit;
    }
}
