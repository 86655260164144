h1,
h2,
h3,
h4,
h5,
h6 {
    color: $black;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.4pt;
    @include ada-outline;
}

div {
    @include ada-outline;
}

.h1,
h1 {
    font-size: $font-size-35px;
}

.h2,
h2 {
    font-size: $font-size-25px;
}

.h3,
h3 {
    font-size: $font-size-20px;
}

.h4,
h4 {
    font-size: $font-size-18px;
}

.h5,
h5 {
    font-size: 1rem;
}


h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6{
    font-weight: 700;
    letter-spacing: 0.4pt;
}

p{
    font-weight: 300;
}

p,
span {
    letter-spacing: inherit;

    @include media-breakpoint-up(sm) { // breakpoint(medium)
        // letter-spacing: 0.03em;
    }

    &.big {
        font-size: 1.25rem; // 20px;
    }

    &.regular {
        font-size: 1rem; // 16px; default
    }

    &.medium {
        font-size: $font-size-14px; // 14px;
    }

    &.small {
        font-size: $font-size-12px; // 12px;
    }
}

a {
    font-size: inherit;
    letter-spacing: inherit;
    text-decoration: none;

    &:focus {
        @include no-outline;
    }

    &:hover {
        text-decoration: underline;
    }

    &.underlined{
        span{
            border-bottom: 1px solid $black;
            padding-bottom: 3px;
        }

        &:hover{
            text-decoration: none;

            span{
                border-bottom: 1px solid transparent;
            }
        }
    }

    @include ada-outline;
}

ul {
    &.disc {
        list-style: disc;
    }

    &.inside {
        list-style-position: inside;
    }
}

.page-title{
    text-transform: uppercase;
    font-size: $font-size-25px;
    font-weight: 700;
    line-height: 1.1;

    @include media-breakpoint-up(lg){
        font-size: $font-size-35px;
    }
}

.font-primary {
    font-family: $font-family-primary;
}

.font-secondary {
    font-family: $font-family-secondary;
}

.font-tertiary {
    font-family: $font-family-tertiary;
}

.uppercase{
    text-transform: uppercase !important;
}

.lowercase{
    text-transform: lowercase !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.capitalize-first {
    text-transform: initial !important;
}

.text-shadow {
    text-shadow: -1px -1px 12px rgba(10, 10, 10, 0.7), 1px 1px 12px rgba(10, 10, 10, 0.7);
}

.weight-light{
    font-weight: 300 !important;
}

.weight-regular{
    font-weight: 400 !important;
}

.weight-medium{
    font-weight: 500 !important;
}

.weight-semibold{
    font-weight: 600 !important;
}

.weight-bold{
    font-weight: 700 !important;
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}
