// Color system
//------------------------------------
$primary:       $black !default;
$secondary:     $black !default;
$info:         #08a0be;
$success:     #8AB349;
$warning:     #FFA12E;
$alert:       #FF0000;
$danger:        $alert !default;
$light:         $light-gray !default;
$dark:          $dark-gray !default;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default;


// Typography
//------------------------------------
$font-family-base: "Work Sans", sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.4;
$font-weight-base: 400;


// Grid breakpoints
//------------------------------------
$grid-breakpoints: (
  xs: 0,
  sm: 640px,  // small: 0
  md: 768px,  // medium: 640px
  lg: 1024px, // large: 1024px
  xl: 1280px, // xlarge: 1280px
  xxl: 1440px // xxlarge: 1440px
);

// Grid containers
//------------------------------------
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%
);

// Spacing
//------------------------------------
$grid-gutter-width: 1.5rem;
$spacer: 1rem !default;
$gutters: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

// Border
//------------------------------------
$border-color:    $medium-gray !default;


// Buttons + Forms
//------------------------------------
$input-btn-padding-y:         10px !default;
$input-btn-padding-x:         10px !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:         4px !default;
$input-btn-focus-color-opacity: .25 !default;
//$input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur:          0 !default;
//$input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      4px !default;
$input-btn-padding-x-sm:      8px !default;
$input-btn-font-size-sm:      $font-size-base !default;

$input-btn-padding-y-lg:      8px !default;
$input-btn-padding-x-lg:      16px !default;
$input-btn-font-size-lg:      $font-size-base !default;

$input-btn-border-width:      1px !default;
