.price{
    & > span {
        @include flexbox-left-center();
        flex-wrap: wrap;

        &:last-child{
            margin-right: 0;
        }
    }

    .percentage{
        position: absolute;
        top: 10px;
        left: 0;
        z-index: 1;
        @include percentageLabel();
    }

    del{
        margin-right: 20px;
    }

    .sales{
        font-size: inherit;
        font-weight: 700;
        letter-spacing: 0.4pt;
        margin-right: 10px;

        .value{
            font-size: inherit;
        }
    }

    .strike-through{
        text-decoration: line-through;
        font-size: inherit;
        font-weight: 500;
    }

    .promo-lover-card-opener-container{
       .lover-card-opener{
            font-size: $font-size-14px;

            span{
                border-bottom: 0 none;
                padding-bottom: 0;
            }

            &:hover{
                span{
                    border-bottom: 0 none;
                    padding-bottom: 0;
                }
            }
        }
    }
}
