// FIX BOOTSRAP GRID

.justify-content-center{
    justify-content: center !important;
}

.align-items-center{
    align-items: center !important;
}

.align-items-flex-start{
    align-items: flex-start !important;
}

@include media-breakpoint-up(sm){
    .justify-content-sm-center{
        justify-content: center !important;
    }
}

@include media-breakpoint-up(md){
    .justify-content-md-center{
        justify-content: center !important;
    }

    .custom-row-col-md-10{
        flex: 0 0 auto;
        width: 83.33333333%;
        margin-left: auto;
        margin-right: auto;
    }
}

@include media-breakpoint-up(lg){
    .justify-content-lg-center{
        justify-content: center !important;
    }
}

.small-content{ // .row
    & > [class^="col"]{
        //max-width: 665px;
        max-width: 46%;
    }
}

.medium-content{ // .row
    & > [class^="col"]{
        //max-width: 900px;
        max-width: 64%;
    }
}
