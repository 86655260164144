.toggle-pwd-container{
    position: relative;

    input[type="password"]{
        padding-right: 35px;
    }

    .toggle-pwd{
        @include flexbox-center-center();
        width: 30px !important;
        height: 30px !important;
        position: absolute;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        svg{
            width: 20px;
            height: 20px;
        }

        .icon-visible{
            display: none;
        }

        &.visible{
            .icon-hidden{
                display: none;
            }

            .icon-visible{
                display: inline-block;
            }
        }
    }

    .invalid-feedback{
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 0;
    }
}
