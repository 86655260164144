.search-container{
    position: relative;

    @include media-breakpoint-down(lg) { //breakpoint(medium down)
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: $search-container-height_mobile;
        background: $white;
        padding: 0 $page-padding-x;
        margin: 0 !important;
        overflow: hidden;

        &:not(.open){
            display: none;
        }

        form{
            @include flexbox-center-center();
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 15px $page-padding-x_mobile;
    }

    form{
        @include flexbox-left-center();
    }

    input {
        border: 0;
        border-bottom: 1px solid $black;
        margin: 0 0 0 4px;
        padding: 0 0 3px 0;
        width: 90px;
        height: auto;
        color: $black;
        font-size: inherit;
        line-height: 1;

        @include media-breakpoint-down(lg) {
            width: 100%;
            padding-left: 15px;
            line-height: 20px;
        }

        &::placeholder {
            color: $black;
            font-size: inherit;
        }

        &:focus {
            @include no-outline;
            border: 0;
            border-bottom: 1px solid $black;
        }
    }

    .reset-button{
        &:not(.show){
            display: none;
        }
    }
}

.suggestions-wrapper {
    position: absolute !important;
    top: 40px;
    left: 0;
    width: 100%;
    min-height: 80px;
    z-index: $zindex-search;
    display: none;
    background-color: $white;
    border-bottom: 1px solid #ccc;
    padding-left: 35px;
    padding-right: 35px;

    @include media-breakpoint-up(md) {
        padding-left: 35px;
        padding-right: 35px;
    }

    @include media-breakpoint-down(lg) {
        top: calc(#{$header-height_mobile} + #{$search-container-height_mobile});
    }

    .suggestions {
        ul{
            @include resetUl();
            display: inline-block;
            width: 100%;
            height: calc(100vh - #{$header-height_mobile} - #{$search-container-height_mobile} - 50px);
            overflow-y: auto;
            overflow-x: hidden;

            @include media-breakpoint-up(lg){
                height: 375px;
            }

            li{
                &.header{
                    padding-top: 15px;
                    padding-bottom: 10px;
                    font-weight: 600;
                }

                &.items{
                    //@include flexbox-left-top();
                    border-bottom: 1px solid $light-gray;

                    .item{
                        padding-top: 0;
                        padding-bottom: 15px;

                        .item-inner{
                            @include flexbox-left-top();
                        }

                       img{
                            width: 75px;
                            @media screen and (max-width: 480) {
                                width: 60px;
                            }
                        }

                        .name{
                            max-width: 100px;
                            font-size: $font-size-14px;
                            line-height: 1.2;
                            font-weight: 300;
                            padding-left: 10px;
                        }
                    }

                    &.categories{
                        .item{
                            .name{
                                max-width: unset;
                                padding-left: 0;
                            }
                        }
                    }

                    @media screen and (max-width: 480) {
                        &.row-cols-2:not(.categories) > * {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.no-results-product {
    .category-siblings__wrapper {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 18px;
    }

    .error-message {
        padding-bottom: 20px !important;
    }

    h2.error-message{
        font-size: 18px;
    }

    .suggestion-message{
        padding: 50px 0 0px;
    }
}
