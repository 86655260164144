.checkoutpage{
    .checkout-step-header{
        @include flexbox-left-center();
        border-top: 1px solid $medium-gray;
        padding-top: 30px;

        &.no-border-top{
            border-top: 0;
        }

        .checkout-step-title{
            margin-bottom: 0;
            font-size: $font-size-25px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.4pt;
            padding-right: 20px;
        }

        .checkout-step-title + p{
            display: block;
            margin: 15px 0 0;
        }

        button{
            width: auto;
            font-size: $font-size-12px;
            text-transform: uppercase;
            margin-left: auto;
        }
    }

    .checkout-step-body{
        padding-top: 20px;
        padding-bottom: 35px;

        .checkout-message{
            margin-bottom: 15px;
        }

        .summary-section-label{
            font-size: $font-size-18px;
            font-weight: 700;
            letter-spacing: 0.4pt;
            margin-bottom: 15px;
        }

        .summary-details{
            font-weight: 300;

            & + .summary-section-label{
                margin-top: 25px;
            }

            .address-summary,
            .payment-details{
                & > *:not(:last-child){
                    margin-bottom: 5px;
                }

                + .shipping-phone{
                    margin-top: 5px;
                }
            }

            .order-summary-email,
            .order-summary-phone{
                display: block;
                margin: 7px 0;
            }

            .shipping-method-price{
                padding-left: 7px;
            }
        }
    }

    .checkout-step-subtitle{
        font-size: $font-size-18px;
        margin-bottom: 15px;
    }

    .checkout-hidden{
        display: none;
    }

    .address-selector-block{
        &.shipment-selector-block{
            margin-bottom: 20px;
        }

        .address-selector-actions{
            .button-as-link{
                font-size: $font-size-12px;
                text-transform: uppercase;
                margin-bottom: 10px;
            }
        }

        .address-selectbox{
            margin-bottom: 5px;
        }
    }


    /* Customer Step */
    .step-customer{
        .customer-section,
        .create-account,
        .continue-as-guest{
            form,
            .checkout-step-title,
            .checkout-message{
                max-width: 350px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }

            form{
               button{
                    width: 100%;
                }
            }
        }

        .create-account .checkout-step-body,
        .continue-as-guest .checkout-step-body{
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }

        .customer-section{
            .checkout-step-header{
                border-top: 0 none;
                padding-top: 0;
            }

            .forgot-password{
                text-align: right;

                a{
                    display: inline-block;
                    font-size: $font-size-12px;
                    text-transform: uppercase;
                    font-weight: 700;
                    letter-spacing: 0.4pt;
                    margin-bottom: 5px;
                }
            }
        }

        form.login-oauth{
            margin-top: 40px;
        }

        .create-account,
        .continue-as-guest{
            .button-primary{
                width: 100%;
            }
        }
    }


    /* Shipping Step */
    .step-shipping{
        .shipping-section{
            .shipping-method-list{
                .shipping-method{
                    margin-top: 12px;
                    margin-bottom: 12px;
                    font-weight: 300;

                    .shipping-method-pricing{
                        font-weight: 600;
                        padding-left: 10px;
                    }
                }
            }

            .gift-message-block{
                margin: 20px 0 0;
                font-weight: 300;

                .gift-message{
                    margin-top: 15px;
                }
            }
        }
    }


    /* Payment Step */
    .step-payment{
        .billing-address-block{
            .request-invoice-block{
                margin-top: 20px;
            }
        }

        .gift-card-block{
            label{
                font-weight: 700;
                font-size: $font-size-14px;
                text-transform: unset;
                letter-spacing: 0.4pt;
                color: $black;
            }

            button{
                margin-top: 23px;
                padding-top: 14px;
                padding-bottom: 14px;
            }
        }

        .payment-information{
            .payment-options{
                @include resetUl();

                .nav-item{
                    margin-bottom: 10px;

                    .nav-link{
                        @include flexbox-left-center();

                        .radiobox{
                            position: relative;
                            width: 20px;
                            height: 20px;
                            border-radius: 10px;
                            border: 1px solid $black;
                            background-color: $white;
                            margin-right: 7px;
                        }

                        .label{
                            font-weight: 300;
                        }

                        .label + img{
                            margin-left: 20px;
                            height: 25px;
                        }

                        .adyen-option {
                            display: none;
                        }

                        &.klarna-tab{
                            img{
                                width: 50px;
                                height: auto;
                            }
                        }

                        &.active{
                            .radiobox{
                                background-color: $black;

                                &:after{
                                    content: "";
                                    display: inline-block;
                                    position: absolute;
                                    width: 8px;
                                    height: 8px;
                                    border-radius: 4px;
                                    background-color: $white;
                                    left: 5px;
                                    top: 5px;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        #paymentMethodsList {
            padding: 0;

            .additionalFields{
                padding: 15px 0;

                &:empty {
                    padding: 0;
                }
            }

            .paymentMethod_img {
                max-width: 40px;
            }
        }

        .credit-card-selection-new{
            fieldset.payment-form-fields{
                margin-bottom: 0;
            }
        }

        .user-payment-instruments{
            margin: 20px 0;

            .saved-payment-instrument{
                .saved-payment-information{
                    padding-right: 25px;
                }
            }

            button.add-payment{
                margin-top: 15px;
                font-size: 0.75rem;
                text-transform: uppercase;
            }
        }

        fieldset.credit-card-form{
            margin-top: 15px;
            margin-bottom: 0;
        }

        .cancel-new-payment{
            margin: 20px 0 0;
        }
    }
}
