button {
    @include ada-outline;
}

label{
    .is-invalid-input::placeholder,
    .is-invalid::placeholder {
        color: $input-placeholder-color;
    }
}

input::placeholder{
    font-size: $font-size-14px;
    font-weight: 400;
    color: #7F7F7F;
}

input:-internal-autofill-selected{
    background-color: $white !important;
}

.custom-select {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
    background-position-x: 98%;
    background-position-y: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-size: 8px 10px;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: $white;
}

input,
select,
textarea{
    font-size: $font-size-14px;
    color: $black;
    border: 1px solid $black;
    border-radius: 0;
    padding: 10px;

    &:focus,
    &:focus-visible{
        border-color: $black;
        outline: none;
    }
}

input{
    line-height: 28px;
}

select {
    padding: 15px 10px;
}

fieldset {
    margin-bottom: 15px;
    width: 100%;
}

textarea {
    min-height: 100px;
}

legend {
    margin-bottom: 15px;
}

.invalid-feedback{
    font-size: 0.625rem;
    color: $alert;
    text-align: left;
}

.alert-danger {
    color: $alert;
    font-weight: bold;
}

.field{
    margin-bottom: 20px;

    input,
    select,
    textarea{
        width: 100%;
        font-weight: 400;
    }

    label{
        display: block;
        font-size: 0.625rem;
        font-weight: 400;
        color: #7F7F7F;
        letter-spacing: 0.3pt;
        text-transform: uppercase;
        margin-bottom: 3px;
        text-align: left;

        &.label-with-info{
            position: relative;
            button.info-icon{
                span.icon{
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    border-radius: 8px;
                    font-size: $font-size-12px;
                    color: $black;
                    line-height: 1;
                    padding: 1px;
                    border: 1px solid $black;
                    letter-spacing: 0;
                    margin: 0 4px;
                }

                span.tooltip{
                    position: absolute;
                    top: 100%;
                    left: 0;
                    font-size: inherit;
                    font-weight: 300;
                    letter-spacing: normal;
                    padding: 5px 7px;
                    background-color: $white;
                    border: 1px solid $light-gray;
                    text-align: center;
                }

                &:hover{
                    span.tooltip{
                        display: block !important;
                    }
                }
            }
        }
    }

    .example{
        display: block;
        font-size: 0.625rem;
        color: #7F7F7F;
    }

    &.required{
        label{
            &::after{
                content: "*";
                font-size: $font-size-12px;
                margin-left: 0px;
                line-height: 1;
            }
        }
    }
}

.radio-field{
    @include flexbox-left-center();
    margin-bottom: 20px;

    input[type=radio] {
        position: relative;
        width: 20px;
        height: 20px;

        &:before,
        &:after{
            content: "";
            display: inline-block;
            position: absolute;
        }

        &:before{
            width: 20px;
            height: 20px;
            border-radius: 10px;
            border: 1px solid $black;
            background-color: $white;
            left: 0;
            top: 0;
            z-index: 1;
        }

        &:checked{
            &:before{
                background-color: $black;
            }

            &:after{
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background-color: $white;
                left: 6px;
                top: 6px;
                z-index: 2;
            }
        }

        & + label{
            text-align: left;
            margin-left: 10px;
        }
    }

}

.checkbox-field{
    @include flexbox-left-center();
    margin-bottom: 20px;

    input[type=checkbox] {
        position: relative;
        width: 20px;
        height: 20px;
        flex: 0;

        &:before,
        &:after{
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 0;
        }

        &:before{
            border: 1px solid $black;
            background-color: $white;
            z-index: 1;
        }

        &:checked{
            &:before{
                background-color: $black;
            }

            &:after{
                background: transparent url('../images/svg/checkmark.svg') no-repeat center center;
                z-index: 2;
            }
        }

        & + label{
            text-align: left;
            margin-left: 10px;
        }
    }

    &.privacy-policy{
        //margin-top: 15px;
        align-items: flex-start;
        input{
            margin-top: 3px;
        }
        input[type=checkbox] + label{
            margin-left: 20px;
        }
        label,
        a,
        p{
            font-size: $font-size-14px;
            font-weight: 400;
        }

        p{
            margin: 0;
        }

        a{
            text-decoration: underline;

            &:hover{
                text-decoration: none;
            }
        }
    }

    /* .invalid-feedback{
    } */
}

.radio-field,
.checkbox-field{
    position: relative;

    label{
        &.small{
            font-size: $font-size-14px;
        }

        &.light{
            font-weight: 300;
        }
    }

    &.required{
        label{
            p{
                &::after{
                    content: "*";
                    font-size: $font-size-12px;
                    margin-left: 0px;
                    line-height: 1;
                    padding-left: 5px;
                }
            }
        }
    }

    input.is-invalid{
        &:before{
            border-color: $alert;
        }
    }

    .invalid-feedback{
        position: absolute;
        top: 100%;
        z-index: 1;
    }
}

.is-invalid:not(:focus) {
    border-color: red;
    background-color: #f9ecea;
}

.is-invalid:not(:focus)::-webkit-input-placeholder {
    color: red;
}

.is-invalid:not(:focus):-ms-input-placeholder {
    color: red;
}

.is-invalid:not(:focus)::-ms-input-placeholder {
    color: red;
}

.is-invalid:not(:focus)::placeholder {
    color: red;
}

.is-invalid-input:not(:focus),
.is-invalid:not(:focus) {
    background-color: $white;
}

.form-actions{
    button,
    .button-primary,
    .button-secondary{
        min-width: 150px;
    }

    &.button-full-width{
        button,
        .button-primary{
            width: 100%;
        }
    }

    &.two-buttons{
        button,
        .button-primary,
        .button-secondary{
            width: 100%;
        }

        a.button-primary,
        a.button-secondary{
            text-align: center;
        }

        @include media-breakpoint-down(md){
            [class^="col"]:first-child{
                margin-bottom: 30px;
            }
        }

        @include media-breakpoint-up(md){
            [class^="col"]:first-child{
                padding-right: 10px;
            }
            [class^="col"]:last-child{
                padding-left: 10px;
                text-align: right;
            }
        }
    }
}

form.login-oauth{
    @include flexbox-top-center($flex-direction: column);

    a{
        width: 100%;
        text-align: center;

        &.oauth-facebook{
            svg.white{
                display: none;
            }

            span{
                margin-right: 21px;
            }

            &:hover{
                svg.black{
                    display: none;
                }

                svg.white{
                    display: inline;
                }
            }
        }
    }

    a + a{
        margin-top: 20px;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}
