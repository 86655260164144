// GENERIC SLIDER

.wg40 {
    &.generic-slider{
        .slider .slick-dots{
            display: none !important;
        }

        &.dots{
            .slider .slick-dots{
                display: flex !important;
            }
        }
    }

    &.small-products-slider{
        @include flexbox-center-center();
        height: 100%;

        .slider{
            max-width: 315px;
            padding: 0 50px;

            @media (min-width: 415px) {
                max-width: 415px;
            }

            @include media-breakpoint-up(md) {
                max-width: 80%;
            }
            @include media-breakpoint-up(lg) {
                max-width: 415px;
            }

            .slick-dots{
                width: 100%;

                @include media-breakpoint-up(md) {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 1;
                }
            }
        }
    }


    &.content-cols-2{
        @include media-breakpoint-down(md){
            .slick-arrow{
                top: calc(50% + 45px) !important;
            }
        }

        .slick-slide{
            @include flexbox-center-center();
            flex-wrap: wrap;

            .container-img {
                &[data-link] {
                    cursor: pointer;
                }

                &[data-link=""] {
                    cursor: inherit;
                }
            }

            .container-img,
            .wg-text-box{
                width: 100%;
                margin: 0;

                @include media-breakpoint-up(md){
                    width: 50%;
                }
            }

            .wg-text-box{
                padding: 33px 25px;

                @include media-breakpoint-up(sm){
                    padding-left: 35px;
                    padding-right: 45px;
                }

                h3{
                    @include media-breakpoint-up(lg){
                        font-size: $font-size-35px;
                    }
                }

                @include media-breakpoint-up(md){
                    padding: 0 45px 0 35px;

                    & > *{
                        max-width: 400px;
                        margin-left: auto;
                        margin-right: auto;
                    }


                    & > a,
                    & > .button{
                        display: block;
                        text-align: center;
                    }
                }
            }
        }
    }
}
