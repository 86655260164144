.create-account-page{
    .creating-account-advantages{
        .wg-text-box{
            max-width: 900px;
            width: 100%;
            background-color: $very-light-gray;
        }
    }

    form{
        .privacy-policy{
            margin-top: 15px;
        }
    }
}

.account-page{
    .content-col-left{
        padding-bottom: 25px;
        .card.lover-card{
            display: block;
        }

        @include media-breakpoint-up(md){
            padding-bottom: 0;
            .card.lover-card{
                display: none;

                & + .card{
                    margin-top: 0;
                }
            }
        }
    }

    .content-col-right{
        .card.lover-card{
            display: none;
        }

        @include media-breakpoint-up(md){
            .card.lover-card{
                display: block;
            }
        }
    }

    p.empty{
        display: block;
        font-size: $font-size-18px;
        font-weight: 500;
        margin: 20px auto 40px;
    }

    .payment-form {

        .btn-save {
            margin-top: 30px;
        }
    }

    .card:not(.product-info){
        font-weight: 300;
        padding: 23px 20px 25px;
        border: 1px solid $light-gray;

        .card-header{
            @include flexbox-left-center();
            flex-wrap: wrap;
            margin-bottom: 15px;

            h2{
                font-size: $font-size-18px;
                text-transform: uppercase;
                margin-right: 25px;

                @include media-breakpoint-up(md){
                    font-size: $font-size-25px;
                }
            }

            a{
                margin-left: auto;
            }
        }

        .card-body{
            &.card-info-group{
                & > div:not(.dashboard-cards-block-title){
                    margin: 3px 0;
                }
            }
        }

        .card-footer{
            margin-top: 23px;
            padding-top: 25px;
            border-top: 1px solid $medium-gray;

            .button-primary{
                width: 100%;
                text-align: center;
            }
        }

        & + .card{
            margin-top: 25px;
        }

        dl{
            dt{
                display: block;
                font-size: 0.625rem;
                font-weight: 400;
                color: #7F7F7F;
                letter-spacing: 0.3pt;
                text-transform: uppercase;
                margin-bottom: 3px;
                text-align: left;
            }
        }

        .dashboard-cards-block-title{
            font-weight: 700;
            font-size: 1rem;
            letter-spacing: 0.4pt;
            margin-bottom: 10px;

            @include media-breakpoint-up(md){
                font-size: $font-size-18px;
            }
        }

        .dashboard-info{
            font-weight: 500;
        }

        &.lover-card{
            background-color: $very-light-gray;
            font-size: $font-size-14px;

            .lover-card-state_middle{
                margin-top: 20px;
            }

            .lover-card-state_top{
                p{
                    margin-bottom: 10px;

                    &.state{
                        font-size: 1rem;
                    }

                    .label{
                        margin-right: 5px;
                    }

                    strong{
                        font-weight: 700;
                        letter-spacing: 0.4pt;
                    }
                }

                .image-container{
                    padding-right: 15px;

                    img{
                        display: inline-block;
                        width: 100%;
                        max-width: 120px;

                        @include media-breakpoint-up(sm){
                            max-width: 166px;
                        }

                        @media screen and (min-width: 640px)  and (max-width: 960px) {
                            max-width: 135px;
                        }
                    }
                }
            }

            .lover-card-state_bottom{
                margin-top: 25px;
                background-color: $white;
                padding: 13px 20px;
                border-radius: 7px;
                border-left: 7px solid $black;

                .icons,
                .messages,
                .link{
                    margin-top: 7px;
                    margin-bottom: 7px;
                }

                .messages{
                    padding-left: 20px;
                    padding-right: 20px;

                    p{
                        margin-bottom: 0;
                    }
                }

                .link{
                    text-align: right;
                    width: 150px;
                    flex: 0 0 auto;

                    @include media-breakpoint-down(lg){
                        width: 100%;
                        flex: 1 0 100%;
                    }
                }

                @include media-breakpoint-down(md){
                    .icons{
                        order: 1;
                    }

                    .link{
                        order: 2;
                        width: auto;
                        flex: 0 0 auto;
                        margin-left: auto;
                    }

                    .messages{
                        order: 3;
                        width: 100%;
                        max-width: 100%;
                        flex: 1 0 100%;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }

                .lover-card-state_icon-down,
                .lover-card-state_icon-up,
                .state-down-message,
                .state-up-message{
                    display: none;
                }

                .lover-card-state_icon-down,
                .lover-card-state_icon-up{
                    width: 42px;
                    height: 27px;
                }

                .state-down-message,
                .state-up-message{
                    font-weight: 300;

                    strong{
                        font-weight: 500;
                    }
                }

                .state-down-message{
                    strong{
                        color: #D90000;
                    }
                }

                .state-up-message{
                    strong{
                        color: #4BA838;
                    }
                }

                &.down{
                    .lover-card-state_icon-down{
                        display: inline;
                    }
                    .state-down-message{
                        display: block;
                    }

                    /* .lover-card-state_icon-up,
                    .state-up-message{
                        display: none;
                    } */
                }

                &.up{
                    .lover-card-state_icon-up{
                        display: inline;
                    }

                    /* .lover-card-state_icon-down,
                    .state-down-message{
                        display: none;
                    } */

                    .state-up-message{
                        display: block;
                    }
                }
            }
        }

        &.address,
        &.payments{
            border: 0 none;
            border-bottom: 1px solid $medium-gray;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 25px;

            .card-body{
                @include flexbox-left-center();
                flex-wrap: wrap;

                & > * {
                    width: 100%;
                    flex: 0 0 auto;
                    //max-width: 100%;
                    //flex: 1 0 0%;
                }

                & > .last-info-item{
                    width: auto;
                    flex: 0 0 auto;
                }

                .toolbar{
                    @include flexbox-right-center();
                    flex-wrap: wrap;
                    width: 100%;
                    max-width: 100%;
                    flex: 1 0 0%;
                    text-align: right;
                    margin: 0;

                    & > *{
                        margin-top: 3px;
                        margin-bottom: 3px;
                    }

                    .icon-button{
                        margin-left: 20px;

                        svg{
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .card-make-default-link{
                        display: inline-block;
                        margin-left: 20px;

                        a{
                            font-size: $font-size-12px;
                            font-weight: 700;
                            letter-spacing: 0.4pt;
                        }
                    }
                }
            }
        }
    }

    .order-list-container{
        .order-item{
            .item-details.order-info{
                &.order-wrapper,
                &.return-wrapper{
                    .product-summary-block{
                        display: none;
                    }
                }

                &.return-wrapper{
                    padding: 0;
                    background-color: transparent;
                }

                .order-status,
                .return-status{
                    padding-left: 0;
                    font-size: 1rem;
                    font-weight: 600;
                }
            }
        }
    }
}

.back-to-may-account{
    text-align: center;
    padding: 20px;
    margin-top: 40px;
}
