.button,
.btn{
    cursor: pointer;
    display: inline-block;
    font-size: $font-size-14px;
    font-weight: 700;
    text-transform: uppercase;

    &.primary:not(.white),
    &-primary:not(.white){
        @include primary-button();

        &:disabled{
            opacity: 0.2;
        }
    }

    &.primary.white,
    &-primary.white{
        @include primary-button(
            $color: $black,
            $background-color: $white,
            $border-color: $white,
            $color-hover: $white,
            $background-color-hover: $black
        );
    }

    &.secondary:not(.white),
    &-secondary:not(.white){
        @include secondary-button();
    }

    &.secondary.white,
    &-secondary.white{
        @include secondary-button();
    }

    &.primary,
    &-primary,
    &.secondary,
    &-secondary{
        &:disabled{
            cursor: not-allowed;
            pointer-events: all !important;
        }
    }
}

[class^="button"],
[class^="btn"]{
    &.block {
        width: 100%;
    }

    &.mobile-full-width {
        @include media-breakpoint-down(sm) { //breakpoint(small only)
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &:disabled,
    &.disabled{
        opacity: 0.2;
        cursor: not-allowed;
    }

    &.arrow{
        display: inline-block;
        font-size: $font-size-14px;
        font-weight: 700;
        letter-spacing: 0.4pt;
        text-transform: uppercase;
        background: transparent url('../images/svg/arrow-right.svg') no-repeat right center;
        background-size: 20px 11px;
        padding-right: 27px;

        &.white{
            background-image: url('../images/svg/arrow-right-white.svg');
            background-color: transparent;
            color: $white;
        }

        &.arrow-left{
            background: transparent url('../images/svg/arrow-left.svg') no-repeat left center;
            padding-left: 35px;
            padding-right: 0;
        }
    }
}

button {
    &.button.arrow{
        border-color: transparent;
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
}

[data-whatinput="keyboard"] {
    .button:focus {
        @include outline;
    }
}

.button-as-link{
    &:not(.no-hover){
        @include button-as-link();
    }

    &.no-hover{
        @include button-as-link($hoverState: false);
    }

    &.underlined{
        span:not(.icon, .tooltip){
            border-bottom: 1px solid $black;
            padding-bottom: 3px;
        }

        &:hover{
            span{
                border-bottom: 1px solid transparent;
            }
        }
    }

    &.small{
        font-size: $font-size-12px;
    }

    svg{
        width: 20px;
        height: 19px;
        margin-left: 7px;
    }
}

.icon-button{
    @include icon-button();
}

.button-link-with-icon{
    @include icon-button();
}

a{
    &.underlined{
        text-decoration: none;

        span:not(.icon, .tooltip){
            border-bottom: 1px solid $black;
            padding-bottom: 3px;
        }

        &.white{
            span:not(.icon, .tooltip){
                border-bottom: 1px solid $white;
            }
        }

        &:hover{
            text-decoration: none;
            span{
                border-bottom: 1px solid transparent;
            }
        }
    }

    &.button-primary,
    &.button-secondary{
        text-align: center;
    }

    &.button{
        &:hover{
            text-decoration: none;
        }
    }
}
