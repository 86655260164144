@mixin width-helpers($available-width) {
    @each $widthName, $widthPerc in $available-width {
        &.#{$widthName} {
            width: inherit !important;

            @include media-breakpoint-up(sm) { // breakpoint(medium)
                width: $widthPerc !important;
            }
        }
    }
}

@mixin no-outline {
    outline: none;
    box-shadow: none;
}

@mixin outline {
    outline: none;
    box-shadow: 0 0 4px 1px $black;
}

@mixin ada-outline {
    &:focus {
        @include no-outline;
    }

    [data-whatinput="keyboard"] & {
        &:focus {
            @include outline;

            transition: box-shadow 0.25s ease-in-out;
        }
    }
}

//-------
@mixin accessibleLabel() {
    width: 1px;
    height: 1px;
    border: 0;
    clip: rect(0, 0, 0, 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

@mixin resetUl() {
    list-style: none;
    padding: 0;
    margin: 0;
}

@mixin set-page-padding-x() {
    padding-left: $page-padding-x_mobile;
    padding-right: $page-padding-x_mobile;

    @include media-breakpoint-up(sm){
        padding-left: $page-padding-x;
        padding-right: $page-padding-x;
    }
}

@mixin popover(
    $color: inherit,
    $font-size: inherit,
    $left: -1,
    $right: -1,
    $min-width: unset
) {
    position: relative;

    .popover{
        display: none;
        top: 100%;
        border-radius: 0;
        border-color: $light-gray;
        min-width: $min-width;

        @if $left != -1 {
            left: $left;
        }

        @if $right != -1 {
            right: $right;
        }

        @if $right == -1 and $left == -1 {
            left: 0;
        }

        ul{
            @include resetUl();
            padding-top: 5px;
            padding-bottom: 5px;

            li{
                white-space: nowrap;
                padding: 5px 15px;
                margin: 4px 0;
                color: $color;
                font-size: $font-size;

                a{
                    color: $color;
                    font-size: $font-size;
                    padding: 0 !important;
                    margin: 0 !important;
                    text-transform: none !important;
                }
            }
        }
    }

    &:hover{
        .popover{
            display: block;
        }
    }
}

@mixin percentageLabel() {
    background-color: $black;
    font-size: $font-size-14px;
    font-weight: 600;
    color: $white;
    line-height: 30px;
    text-align: center;
    width: 80px;
}
