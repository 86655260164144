.sitemap-html {
    .item-title {
        font-weight: 500;
        font-size: $font-size-25px;
    }

    hr {
        margin: $spacer-small-size 0;
    }

    .sub {
        margin-bottom: $spacer-small-size;
    }
}
