.page-container.store-locator {
    margin-bottom: 60px;

    .page-title {
        margin: 15px 0 30px 0;
    }

    .map-canvas {
        position: relative;
        height: 100%;
        width: 100%;
        min-height: 685px;
    }

    .store-categories {
        h2 {
            font-size: $font-size-25px;
            text-align: center;
            margin-top: 50px;
            margin-bottom: 0;
            text-transform: uppercase;
        }
    }

    .store-top-categories {

        .item-wrapper {
            margin-top: 30px;

            .content-wrapper {
                height: 100%;
                background-color: $very-light-gray;
                padding-bottom: 5px;

                .button-primary {
                    display: block;
                    padding: 10px 20px;
                }

                a {
                    padding: 10px 20px 5px 20px;
                    display: block;
                }
            }
        }
    }

    .store-locator-container {

        @include media-breakpoint-up(sm) {
            padding-left: 40px;
        }

        .search-form {
            border-bottom: 1px solid $light-gray;

            .search-title {
                text-transform: uppercase;
                font-weight: 700;
                margin-top: 25px;

                @include media-breakpoint-up(sm) {
                    margin-top: 0;
                }
            }

            .storelocator-search {
                @include flexbox(center, flex-end, row);
            }

            fieldset {
                &.container {
                    margin-bottom: 0;
                }
            }

            .field {
                margin-bottom: 25px;
            }

            .detect-location {
                margin-bottom: 25px;
            }
        }

        .results-card {

            .card-header {
                text-transform: lowercase;
                font-size: $font-size-14px;
                font-weight: 300;
                padding: 12px 0 24px 0;
            }
        }

        .store-locator-no-results {
            font-size: $font-size-14px;
            font-weight: 300;
        }

        .results {
            max-height: 450px;
            overflow: auto;
        }

        .store-details {
            border-bottom: 1px solid $light-gray;
            margin: 0 35px 20px 0;
        }
    }

    .store-details,
    .store-detail {
        font-size: $font-size-14px;

        .store-name {
            font-size: $font-size-14px;
            margin-top: 25px;
            margin-bottom: 10px;
            display: inline-block;
            font-weight: 700;

            @include media-breakpoint-up(sm) {
                margin-top: 0;
            }
        }

        .store-map {
            margin-bottom: 10px;
            display: inline-block;
            font-weight: 300;
        }

        .storelocator-phone {
            font-weight: 300;
        }

        .store-address-link {
            @include flexbox(space-between);
        }
    }

    .store-detail {
        .store-name {
            font-size: $font-size-25px;
        }

        .store-address-link {
            margin-bottom: 25px;
        }

        .store-share {
            margin-top: 70px;
        }

        .hours {
            text-align: right;

            @include media-breakpoint-up(sm) {
                text-align: initial;
            }
        }

        .store-editorial {
            margin-bottom: 25px;

            h2 {
                font-size: 1rem;
            }

            .wg {
                &.wg33 {
                    &.container {
                        padding: 0;

                        .image-container {
                            min-height: 195px;

                            @include media-breakpoint-down(md) {
                                width: 50%;
                                flex: 0 0 auto;
                            }
                        }

                        .button {
                            margin-top: 18px;
                        }

                        .content-container {
                            @include media-breakpoint-down(md) {
                                width: 50%;
                                flex: 0 0 auto;
                            }
                        }

                        .wg-text-box {

                            padding: 18px 15px;

                            @include media-breakpoint-up(sm) {
                                padding: 30px 25px 25px 25px;

                            }


                            p {
                                max-height: 65px;
                                overflow: auto;
                            }
                        }
                    }
                }
            }
        }
    }

}
