/*
Common style: Minicart Totals, Cart Page Totals, Checkout Totals
*/
.cart .totals,
.order-total-summary,
.giftcard-total-summary
{

    & > .row:not(.checkout-continue, .minicart-actions){
        margin-bottom: 10px;
    }

    .summary-title{
        text-transform: uppercase;
        font-size: $font-size-25px;
        letter-spacing: 0.4pt;
        margin-bottom: 3px;
        line-height: 1.2;
    }

    .hide-shipping-discount,
    .hide-total-discount,
    .hide-order-discount,
    .hide-gift-card {
        display: none;
    }

    p.label,
    p.value{
        margin-bottom: 0;
    }

    p.value{
        font-weight: 500;
        text-align: right;
        letter-spacing: 0.4pt;
    }

    .promotion-information{
        @include flexbox-left-center();
        justify-content: space-between;
        font-weight: 700;
        letter-spacing: 0.4pt;

        /* .applied-promotion-discount{
            display: none;
        } */
    }

    .spacer {
        width: 100%;
        height: 10px;
    }

    .hr{
        width: 100%;
        height: 2px;
        background-color: $medium-gray;
        margin-bottom: 10px;
    }

    .grand-total-container{
        p.label,
        p.value{
            font-size: 1rem;
        }

        p.value{
            font-weight: 700;
            letter-spacing: 0.4pt;
        }
    }

    .checkout-btn{
        width: 100%;
        text-align: center;

        span{
            width: 100%;
        }
    }

    .text-align-right {
        text-align: right;
    }
}
