/* TABLIST
    per ora non esiste un widget di tipo tabs
    e per ereditare lo style di widgets è stato creato questo class name
*/

.wg.wgtablist{
    .wg-text-box{
        padding: 0 $page-padding-x_mobile;
        margin-top: 0;

        @include media-breakpoint-up(sm) {
            padding: 0 $page-padding-x;
        }

        h2, .h2,
        h3, .h3{
            & + .tablist{
                margin-top: 15px;
            }
        }
    }

    .tablist{
        @include flexbox-left-center();

        button{
            @include button-as-link();
            text-transform: uppercase;
            font-size: 0.75rem;
            margin: 15px 0;

            span{
                border-bottom: 1px solid transparent;
            }

            &[tabindex="0"]{
                span{
                    border-bottom: 1px solid $black;
                }
            }

            & + button{
                margin-left: 30px;
            }
        }
    }

    .tabpanel{
        &[tabindex="-1"]{
            display: none;
        }

        .wg-accordion{
            & > .wg-text-box{
                width: 100%;
            }
        }
    }
}
