.slider{
    .slick-slide {
        @include no-outline;
        position: relative;
    }

    .slick-arrow {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        width: 35px;
        height: 35px;
        background-size: 26px 11px;
        border: 0 none;
        padding: 0;
        border-radius: 0;
        box-shadow: none !important;
        text-indent: -9999px;

        &.slick-prev{
            left: 0;
            background: $white url('../images/svg/arrow-left.svg') no-repeat center center;
        }

        &.slick-next{
            right: 0;
            background: $white url('../images/svg/arrow-right.svg') no-repeat center center;
        }

        &:focus {
            @include outline;
        }

        &.slick-disabled {
            display: none !important;
        }
    }

    .slick-dots {
        @include flexbox-center-center();
        @include resetUl();
        margin: 0 auto;
        padding: 20px 0;
        width: auto;

        li {
            margin: 0 5px;

            button {
                background-color: $dark-gray;
                border-radius: 100%;
                border: none;
                display: block;
                width: 7px;
                height: 7px;
                padding: 0;
                text-indent: -9999px;
            }

            &.slick-active{
                button {
                    background-color: $black;
                    width: 11px;
                    height: 11px;
                }
            }
        }
    }

    &.slick-dotted{
       // padding-bottom: 50px; // ==== slick-dots height
    }

    &.carousel{
        padding-left: calc(#{$page-padding-x_mobile} - #{$padding-tile-x_mobile});
        padding-right: calc(#{$page-padding-x_mobile} - #{$padding-tile-x_mobile});

        .slick-slide{
            padding-left: $padding-tile-x_mobile;
            padding-right: $padding-tile-x_mobile;
        }

        &.slick-dotted{
            .slick-arrow {
                position: absolute;
                top: calc(50% - 50px);
                transform: translateY(calc(50% - 50px)); // 50% - slider-dots height
            }
        }

        .slick-arrow{
            &.slick-prev{
                left: calc(#{$page-padding-x_mobile} - 1px);
            }

            &.slick-next{
                right: calc(#{$page-padding-x_mobile} - 1px);
            }
        }

        @include media-breakpoint-up(sm) {
            padding-left: calc(#{$page-padding-x} - #{$padding-tile-x_mobile});
            padding-right: calc(#{$page-padding-x} - #{$padding-tile-x_mobile});

            .slick-arrow{
                &.slick-prev{
                    left: calc(#{$page-padding-x} - 1px);
                }

                &.slick-next{
                    right: calc(#{$page-padding-x} - 1px);
                }
            }
        }

        @include media-breakpoint-up(md) {
            padding-left: calc(#{$page-padding-x} - #{$padding-tile-x});
            padding-right: calc(#{$page-padding-x} - #{$padding-tile-x});

            .slick-slide{
                padding-left: $padding-tile-x;
                padding-right: $padding-tile-x;
            }
        }
    }
}
