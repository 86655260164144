.notifyme{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10000;
    background-color: $white;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 215px;
    border: 1px solid $light-gray;
    box-shadow: -3px 3px 8px -1px rgba(0,0,0,0.48);

    button.close{
        position: absolute;
        top: 5px;
        right: 5px;
        width: 14px;
        height: 14px;
        background: transparent url('../images/svg/close.svg') no-repeat center center;
        background-size: 10px 10px;
    }

    .notifyme-wrapper{
        padding: 15px 20px 10px 15px;
        font-size: 0.813rem;
        font-weight: 500;
    }

    progress{
        width: 100%;
        height: 7px;
        border: 0 none;
        border-radius: 0;
        margin: 0;
    }

    progress::-webkit-progress-bar {
        background-color: $light-gray;
    }

    progress::-webkit-progress-value {
        background-color: rgb(81, 160, 36);
        border-radius: 0;
    }

    progress::-moz-progress-bar{
        background-color: rgb(81, 160, 36);
        border-radius: 0;
    }

    &.error{
        progress::-webkit-progress-value {
            background-color: rgb(221, 15, 15);
            border-radius: 0;
        }
    }
}
