.homepage {
    .linked-categories-carousel {
        margin: $margin-50-0;
        a.slick-slide {
            text-decoration: none;
            text-transform: uppercase;
            display: grid;
            align-items: center;
            text-align: center;
            font-size: $font-size-20px;

            &.image {
                color: $medium-gray;

                img {
                    margin-bottom: $marginb-10;
                    width: 100%;
                }

                &:hover {
                    color: $black;
                    cursor: pointer;

                    img {
                        border: 1px solid $black;
                    }
                }
    
                @include media-breakpoint-down(lg){
                    color: $black;
                }
            }
        }
    }
}