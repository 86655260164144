.breadcrumb,
.breadcrumbs-list {
    width: 100%;
    margin: 10px 0;
    padding: 0;
    @include flexbox-left-center();
    flex-wrap: wrap;
    list-style: none;
}

.breadcrumb-item {
    font-size: $font-size-14px;

    &:not(:first-child){
        padding: 5px 0 5px 7px;

        &:before{
            content: "/";
            padding-right: 1px;
            position: relative;
            top: 1px;
        }
    }

    &:first-child{
        padding: 5px 0;
    }

    &:last-child{
        a, span {
            font-weight: 300;
        }
    }

    a, span {
        color: $black;
        font-size: inherit;
    }

    span{
        font-weight: 300;
    }
}

.breadcrumb-item + .breadcrumb-item{

}

.breadcrumb.mobile{
    margin-bottom: 5px;

    .breadcrumb-item{
        &:first-child{
            padding: 0;
        }

        button{
            font-weight: 400;
            span{
                font-weight: inherit;
                color: $black;
            }

            &.button-as-link svg{
                margin-left: 0;
            }
        }
    }
}

.breadcrumbs-list {
    li {
        margin-left: 0;
        margin-bottom: 0;
        position: relative;
        list-style: none;

        &.active {
            color: $black;
            font-weight: bold;
        }

        &.list-separator {
            background-color: $black;
            height: 1px;
            margin: 0 50px;
            flex: 1;
        }
    }
}

.editorial-breadcrumb-container{
    position: relative;

    .editorial-breadcrumb:not(.mobile){
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        @include set-page-padding-x();

        .breadcrumb-item:not(:first-child){
            padding: 5px;

            &:before{
                margin-right: 5px;
            }
        }
    }

    .editorial-breadcrumb.mobile{
        $page-padding-x_mobile: 12px;

        padding-left: $page-padding-x_mobile;
        padding-right: $page-padding-x_mobile;

        .breadcrumb{
            margin-bottom: 15px;
        }
    }

    &.white{
        .breadcrumb-item,
        .breadcrumb-item a,
        .breadcrumb-item span{
            color: $white;
        }
    }
}
